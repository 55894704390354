/* eslint-disable no-negated-condition */
import React from 'react';
import { Badge, DropdownContent, DropdownTrigger, DropdownWrapper, Wrapper } from './BadgeDropdownList.styled';

export type BadgeItem<T = void> = {
    id: string;
    label: string;
} & (T extends void ? {} : { data: T });

export type BadgeDropdownListProps<T = void> = {
    items?: BadgeItem<T>[];
    onBadgeClick?: ((item: BadgeItem<T>) => void) | undefined;
    renderBadge?: (item: BadgeItem<T>) => React.ReactNode;
};

export function BadgeDropdownList<T = void>({ items = [], renderBadge, onBadgeClick }: BadgeDropdownListProps<T>) {
    const visible = items.slice(0, 3);
    const hidden = items.slice(3);

    return (
        <Wrapper>
            {visible.map((item) => (
                <Badge
                    key={item.id}
                    {...(onBadgeClick != null ? { onClick: () => onBadgeClick(item) } : {})}
                >
                    {renderBadge != null ? renderBadge(item) : item.label}
                </Badge>
            ))}

            {hidden.length > 0 && (
                <DropdownWrapper>
                    <DropdownTrigger>+{hidden.length}</DropdownTrigger>
                    <DropdownContent>
                        {hidden.map((item) => (
                            <Badge
                                key={item.id}
                                {...(onBadgeClick != null ? { onClick: () => onBadgeClick(item) } : {})}
                            >
                                {renderBadge != null ? renderBadge(item) : item.label}
                            </Badge>
                        ))}
                    </DropdownContent>
                </DropdownWrapper>
            )}
        </Wrapper>
    );
}
