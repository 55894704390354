import { observer } from 'mobx-react-lite';
import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { Wrapper } from './BonusTermsAndConditions.styled';

export const BonusTermsAndConditions = observer(() => {
    const {
        appPlayersState: { bonusState },
    } = useAppStateContext();

    return (
        <Wrapper>
            <p>{bonusState.getSelectedFreeSpinDescription}</p>
        </Wrapper>
    );
});
