import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const BonusListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const BonusCard = styled.div`
    border: 1px solid rgba(6, 12, 24, 0.08);
    padding: 16px;
    border-radius: 4px;
    background-color: #fff;
`;

export const BonusCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12px;
`;

export const BonusCardTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
`;

export const BonusCardTitle = withConfig(
    (theme) => styled.h3`
        font-weight: ${theme.star.fontWeight.bold};
        font-size: ${theme.star.fontSize.xMedium};
        margin: 0;
    `
);

export const BonusCardLabel = withConfig(
    (theme) => styled.span`
        font-size: ${theme.star.fontSize.small};
        margin: 0;
    `
);

export const BonusCardButton = styled(Button)`
    width: 100%;
`;

export const BonusCardContent = styled.div`
    margin-bottom: 15px;
`;

export const BonusDetailRow = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
`;

export const BonusTag = withConfig(
    (theme) => styled.span`
        background-color: rgba(28, 27, 31, 0.1);
        width: fit-content;
        border-radius: 18px;
        padding: 0px 5px;
        font-size: ${theme.star.fontSize.xSmall};
    `
);

export const BonusCardActions = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-top: 12px;
`;
