import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Bonus } from 'src/domains/players/state/BonusState';
import { Badge } from '../../../BadgeDropdownList/BadgeDropdownList.styled';
import { DetailLabel, DetailRow, Details, DetailValue } from './FreeSpinDetails.styled';

export const FreeSpinDetails = ({ item }: FreeSpinDetailsProps) => {
    return (
        <Details>
            {item.usageConditions.map(({ type, value, details }) => {
                return (
                    <DetailRow key={`${item.bonusId}-${type}`}>
                        <DetailLabel>
                            {type === 'casino-game' ? (
                                <I18n
                                    langKey='account.freeSpins.label.eligibleGames'
                                    defaultText='Eligible Games:'
                                />
                            ) : (
                                <I18n
                                    langKey='account.freeSpins.label.playThrough'
                                    defaultText='Play Through:'
                                />
                            )}
                        </DetailLabel>
                        <DetailValue>
                            {details != null &&
                                Object.entries(details).map(([key, value]) => <Badge key={key}>{String(value)}</Badge>)}
                            {details == null &&
                                (Array.isArray(value) ? value : [value]).map((item) => (
                                    <Badge key={item}>{item}</Badge>
                                ))}
                        </DetailValue>
                    </DetailRow>
                );
            })}
        </Details>
    );
};

type FreeSpinDetailsProps = {
    item: Bonus;
};
