import React from 'react';
import { Table, Td, Th, Tr } from './HierarchyTable.styled';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';

type HierarchyTableProps = {
    data: Record<string, string | number>;
};

export const HierarchyTable = ({ data }: HierarchyTableProps) => {
    if (Object.keys(data).length === 0) {
        return <p>No data available</p>;
    }

    return (
        <Table>
            <thead>
                <tr>
                    <Th colSize='40%'>Type</Th>
                    <Th colSize='60%'>Name</Th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(data).map(([key, value], index, array) => (
                    <Tr
                        key={key}
                        isLast={index === array.length - 1}
                    >
                        <Td colSize='40%'>{capitalizeFirstLetter(key)}</Td>
                        <Td colSize='60%'>{String(value)}</Td>
                    </Tr>
                ))}
            </tbody>
        </Table>
    );
};
