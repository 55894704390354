import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const DropdownWrapper = styled.div`
    position: relative;
    display: inline-block;

    &:hover > div {
        display: flex;
    }
`;

export const DropdownTrigger = withConfig(
    (theme) => styled.div`
        background-color: rgba(28, 27, 31, 0.1);
        border-radius: 18px;
        padding: 0px 5px;
        font-size: ${theme.star.fontSize.xSmall};
        cursor: pointer;
        margin-left: 5px;
        min-width: 24px;
        position: relative;
    `
);

export const DropdownContent = styled.div`
    position: absolute;
    top: 80%;
    right: 0;
    width: 104px;
    max-height: 250px;
    overflow-y: auto;
    min-width: min-content;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: none;
    flex-direction: column;
    gap: 4px;
    z-index: 10;
    
    div {
        width: 100%;
        white-space: nowrap;
    }
`;

export const Badge = withConfig(
    (theme) => styled.div`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        background-color: rgba(28, 27, 31, 0.1);
        width: fit-content;
        border-radius: 18px;
        padding: 4px 8px;
        font-size: ${theme.star.fontSize.xSmall};
        line-height: 1.5;
    `
);
