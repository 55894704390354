import * as t from 'io-ts';
import { AccountTypeIO, FirstLegsTypeIO } from './types';
import { createGuard } from 'src_common/common/createGuard';
import { FreeBetCreditsIO, PriceIO } from 'src/domains/sportsbook/betting/betSlipState/BetSlipSheredTypes';

export const SuccessResponseTypeIO = t.interface({
    id: t.number,
    type: t.string,
    currency: t.string,
    stakePerLine: t.string,
    operatorStakePerLine: t.string,
    numLines: t.number,
    totalStake: t.string,
    operatorTotalStake: t.string,
    legs: t.array(FirstLegsTypeIO),
    eachWay: t.boolean,
    status: t.string,
    settledAt: t.null,
    settledBy: t.null,
    placedAt: t.union([t.string, t.null]),
    placedBy: t.union([AccountTypeIO, t.null]),
    account: AccountTypeIO,
    ip: t.union([t.string, t.undefined, t.null]),
    comment: t.null,
    country: t.string,
    channel: t.string,
    stakeFactor: t.number,
    maxBet: t.string,
    potentialReturns: t.string,
    cashOut: t.boolean,
    payout: t.null,
    operatorPayout: t.null,
    profit: t.null,
    operatorProfit: t.null,
    remarks: t.array(t.interface({})),
    freebet: t.union([t.boolean, t.undefined]),
    transaction: t.union([t.unknown, t.undefined]),
    freebetCredits: t.union([t.array(FreeBetCreditsIO), t.null, t.undefined]),
});

export const ReferredBetSlipBetLegTypeIO = t.interface({
    type: t.string,
    sport: t.null,
    eachWayTerms: t.null,
    termsWithBet: t.null,
    eventCountry: t.union([t.string, t.array(t.string), t.array(t.interface({}))]),
    price: t.union([PriceIO, t.null, t.undefined]),
    priceType: t.string,
    eventId: t.union([t.number, t.undefined]),
    marketId: t.union([t.string, t.undefined]),
    selectionId: t.union([t.string, t.undefined]),
    id: t.union([t.string, t.undefined]),
    index: t.union([t.string, t.undefined]),
    stakePerLine: t.union([t.string, t.undefined]),
    eachWay: t.union([t.string, t.undefined]),
    potentialReturns: t.union([t.string, t.undefined]),
});

export const ReferredBetSlipBetTypeIO = t.interface({
    type: t.string,
    correlationID: t.string,
    stakePerLine: t.string,
    legs: t.array(ReferredBetSlipBetLegTypeIO),
    eachWay: t.boolean,
    comment: t.null,
    country: t.union([t.string, t.null, t.undefined]),
    channel: t.string,
    potentialReturns: t.union([t.string, t.null]),
    freebet: t.boolean,
});

export const ReferredBetSlipAccountTypeIO = t.interface({
    id: t.number,
    type: t.string,
    name: t.string,
});

export const ReferredHeaderWhoBetSlipTypeIO = t.interface({
    id: t.number,
    name: t.string,
    type: t.string,
    universe: t.string,
    ip: t.string,
});

export const ReferredHeaderBetSlipTypeIO = t.interface({
    id: t.string,
    type: t.string,
    universe: t.string,
    when: t.string,
    who: ReferredHeaderWhoBetSlipTypeIO,
    clientRequestId: t.null,
});

export const ReferredBetSlipTypeIO = t.interface({
    type: t.string,
    account: ReferredBetSlipAccountTypeIO,
    assignee: t.union([t.null, ReferredBetSlipAccountTypeIO]),
    bets: t.array(ReferredBetSlipBetTypeIO),
    createdAt: t.string,
    expiresAt: t.union([t.null, t.string]),
    ignoreProblems: t.array(t.interface({})),
});

export const ReferralModelTypeIO = t.interface({
    body: t.interface({
        referredBetslip: ReferredBetSlipTypeIO,
        bets: t.array(SuccessResponseTypeIO),
    }),
    header: ReferredHeaderBetSlipTypeIO,
});

export type ReferralModelType = t.TypeOf<typeof ReferralModelTypeIO>;

export const isResponseReferralSocket = createGuard(ReferralModelTypeIO);
