import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    BetslipAllSinglesId,
    BetslipCombinationId,
    BetslipRabId,
    BetslipSingleId,
} from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import {
    Caret,
    CurrencyStyle,
    StakeInputStyle,
    StakeInputWrapper,
} from 'src/domains/sportsbook/betting/ui/betSlip/stakeInputMobx/StakeInputMobx.style';

interface StakeInputNewPropsType {
    selectionId: BetslipSingleId | BetslipCombinationId | BetslipRabId | BetslipAllSinglesId;
}

export const StakeInputMobx = observer('StakeInputMobx', (props: StakeInputNewPropsType) => {
    const appState = useAppStateContext();
    const { selectionId } = props;
    const { isReferred } = appState.appSportsBookState.betSlipState.referralState;
    const { onFocusSelection, isVisibleKeyBoard, focusedSelectionId } =
        appState.appSportsBookState.betSlipState.basicBetSlipState;
    const betslipData = appState.appSportsBookState.betSlipState.betslipData;

    const item = selectionId.getModel();
    if (item === null) {
        return null;
    }

    const { stakeInput } = item;

    if (stakeInput === null) {
        return null;
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        stakeInput.changeFromInput(e.currentTarget.value);
        appState.appSportsBookState.betSlipState.betSlipSummaryState.clearErrors();
    };

    const handleScroll = (): void => {
        const { refElem, legsIds2 } = appState.appSportsBookState.betSlipState;
        const refSelection = betslipData.getRef(selectionId);

        if (refSelection !== null && refElem !== null && legsIds2.length > 1) {
            setTimeout(() => {
                refElem.scrollTo({
                    top: refSelection.offsetTop - 52,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 200);
        }
    };

    const onClick = (e: React.MouseEvent<HTMLInputElement>): void => {
        e.preventDefault();
        if (isReferred === false) {
            onFocusSelection(selectionId);
            handleScroll();
        }
    };

    const onTouchEnd = (e: React.TouchEvent<HTMLInputElement>): void => {
        if (isReferred === false) {
            if (isVisibleKeyBoard) {
                e.preventDefault();
                onFocusSelection(selectionId);
            }
            handleScroll();
        }
    };

    const moneySymbol = appState.appSportsBookState.moneySymbol;
    const placeholder = appState.config.decimalFormatTextForPlaceholder;

    return (
        <StakeInputWrapper>
            <CurrencyStyle>{moneySymbol}</CurrencyStyle>
            <StakeInputStyle
                data-test='stake-input'
                type='tel'
                value={stakeInput.valueForInput}
                onChange={handleOnChange}
                onClick={onClick}
                onTouchEnd={onTouchEnd}
                placeholder={placeholder}
                disabled={focusedSelectionId === selectionId && isVisibleKeyBoard ? true : isReferred}
            />

            {focusedSelectionId === selectionId && isVisibleKeyBoard && <Caret />}
        </StakeInputWrapper>
    );
});
