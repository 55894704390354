/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { action, computed, makeObservable } from 'mobx';
import {
    CategoriesAndGamesResponseType,
    CategoryType,
} from 'src/domains/casino/state/AppCasino/store/game-store/typesExt';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { CasinoGameModelExt } from './CasinoGameModelExt';
import { CollectionType, FilterCategoryType } from './typesExt';
import { CasinoContextModelExt } from './CasinoContextModelExt';
import { CasinoSearchExt } from './CasinoSearchExt';

class Data {
    public readonly gameModels: MobxMapAutoNew<number, CasinoGameModelExt>;

    public constructor(
        public readonly search: CasinoSearchExt,
        public readonly collection: CollectionType,
        public readonly resource: Resource<CategoriesAndGamesResponseType>,
        public readonly contextModel: CasinoContextModelExt
    ) {
        makeObservable(this);
        this.gameModels = new MobxMapAutoNew(
            (gameId: number) => new CasinoGameModelExt(collection, resource, gameId, contextModel)
        );
    }

    @computed.struct public get allGames(): Array<CasinoGameModelExt> {
        const data = this.resource.get();
        if (data.type === 'ready') {
            const games = data.value?.games ?? [];

            return games.map((game) => this.gameModels.get(game.id));
        }

        return [];
    }

    @computed.struct public get allCategories(): Array<CategoryType> {
        const data = this.resource.get();
        if (data.type === 'ready') {
            return data.value?.categories ?? [];
        }

        return [];
    }

    @computed.struct public get isLoading(): boolean {
        const data = this.resource.get();
        return data.type === 'loading' ? true : false;
    }

    public findCategory(test: (data: CategoryType) => boolean): CategoryType | null {
        const data = this.resource.get();

        if (data.type === 'ready') {
            const categories = data.value?.categories ?? [];

            for (const category of categories) {
                if (test(category)) {
                    return category;
                }
            }
        }

        return null;
    }
}

export class CasinoCollectionModelExt {
    private readonly data: Data;
    private readonly getGames: () => Array<CasinoGameModelExt>;

    public constructor(data: Data, getGames: () => Array<CasinoGameModelExt>) {
        makeObservable(this);
        this.data = data;
        this.getGames = getGames;
    }

    public static newFromParams(
        search: CasinoSearchExt,
        collection: CollectionType,
        resource: Resource<CategoriesAndGamesResponseType>,
        contextModel: CasinoContextModelExt
    ): CasinoCollectionModelExt {
        const data = new Data(search, collection, resource, contextModel);
        return new CasinoCollectionModelExt(data, () => data.allGames);
    }

    public getGameModel(gameId: number): CasinoGameModelExt {
        return this.data.gameModels.get(gameId);
    }

    public async refresh(): Promise<void> {
        await this.data.resource.refresh();
    }

    @computed public get isSearchActive(): boolean {
        return this.data.search.isActive();
    }

    @computed public get searchValue(): string {
        return this.data.search.searchValue;
    }

    @action public setSearchValue(phrase: string): void {
        this.data.search.setSearch(phrase);
    }

    @computed public get games(): Array<CasinoGameModelExt> {
        return this.getGames();
    }

    @computed public get categories(): Array<CategoryType> {
        return this.data.allCategories;
    }

    @computed public get isLoading(): boolean {
        return this.data.isLoading;
    }

    public filterBySearch(): CasinoCollectionModelExt {
        return new CasinoCollectionModelExt(this.data, (): Array<CasinoGameModelExt> => {
            const gameModels = this.getGames();

            if (this.data.search.isActive()) {
                return this.data.search.filter(gameModels);
            }
            return gameModels;
        });
    }

    public filterByCategoryId(categoryId: number): CasinoCollectionModelExt {
        return new CasinoCollectionModelExt(this.data, (): Array<CasinoGameModelExt> => {
            const categoryGamesIds = this.data.findCategory((category) => category.id === categoryId)?.game_ids ?? [];
            const gameModels = this.getGames().filter((game) => categoryGamesIds.includes(game.id));
            return this.sortGamesByIdOrder(gameModels, categoryGamesIds);
        });
    }

    public filterByCategoryName(categoryName: string): CasinoCollectionModelExt {
        return new CasinoCollectionModelExt(this.data, (): Array<CasinoGameModelExt> => {
            const categoryGamesIds =
                this.data.findCategory((category) => category.name === categoryName)?.game_ids ?? [];
            const gameModels = this.getGames().filter((game) => categoryGamesIds.includes(game.id));
            return this.sortGamesByIdOrder(gameModels, categoryGamesIds);
        });
    }

    public filterByVisibleInAllGames(): CasinoCollectionModelExt {
        return new CasinoCollectionModelExt(this.data, (): Array<CasinoGameModelExt> => {
            const gameIds = this.getGameIdsFromCategories({ isVisibleInAllGames: true });
            const gameModels = this.getGames().filter((game) => gameIds.has(game.id));
            return this.sortGamesByIdOrder(gameModels, Array.from(gameIds));
        });
    }

    public getAllVirtualGames(): CasinoCollectionModelExt {
        return new CasinoCollectionModelExt(this.data, (): Array<CasinoGameModelExt> => {
            const gameIds = this.getGameIdsFromCategories({});
            const gameModels = this.getGames().filter((game) => gameIds.has(game.id));
            return this.sortGamesByIdOrder(gameModels, Array.from(gameIds));
        });
    }

    public filterByFavouriteGames(): CasinoCollectionModelExt {
        return new CasinoCollectionModelExt(this.data, (): Array<CasinoGameModelExt> => {
            const gameIds = this.data.contextModel.favouriteGames.getReady()?.game_ids ?? [];
            return this.getGames().filter((game) => gameIds.includes(game.id));
        });
    }

    private getGameIdsFromCategories(filters: FilterCategoryType): Set<number> {
        const categories = this.getCategories(filters);
        const gameIds: Set<number> = new Set();

        for (const category of categories) {
            for (const gameId of category.game_ids) {
                gameIds.add(gameId);
            }
        }
        return gameIds;
    }

    private sortGamesByIdOrder(
        gameModels: Array<CasinoGameModelExt>,
        gameIds: Array<number>
    ): Array<CasinoGameModelExt> {
        const sortedGameModels: Array<CasinoGameModelExt> = [];
        for (const gameId of gameIds) {
            const gameModel = gameModels.find((game) => game.id === gameId);

            if (gameModel !== undefined) {
                sortedGameModels.push(gameModel);
            }
        }
        return sortedGameModels;
    }

    public getCategoryByName(name: string): CategoryType | null {
        return this.data.allCategories.find((category) => category.name.toLowerCase() === name.toLowerCase()) ?? null;
    }

    public getCategories(filters: FilterCategoryType): Array<CategoryType> {
        let categories = this.data.allCategories;

        if (filters.isFixed !== undefined) {
            categories = categories.filter((category) => category.fixed === filters.isFixed);
        }

        if (filters.isVisibleInNavigation !== undefined) {
            categories = categories.filter(
                (category) => category.visible_in_navigation === filters.isVisibleInNavigation
            );
        }

        if (filters.isVisibleInAllGames !== undefined) {
            categories = categories.filter((category) => category.visible_in_all_games === filters.isVisibleInAllGames);
        }

        if (filters.hasGames !== undefined) {
            if (filters.hasGames === true) {
                categories = categories.filter((category) => category.game_ids.length > 0);
            } else {
                categories = categories.filter((category) => category.game_ids.length === 0);
            }
        }

        if (filters.containsGameId !== undefined) {
            const gameId = filters.containsGameId;
            categories = categories.filter((category) => category.game_ids.includes(gameId));
        }
        return categories;
    }

    @computed public get activeCategories(): Array<CategoryType> {
        return this.getCategories({ hasGames: true });
    }

    @computed public get activeCategoriesVisibleInNavigation(): Array<CategoryType> {
        return this.getCategories({ isVisibleInNavigation: true });
    }

    @computed public get fixedCategories(): Array<CategoryType> {
        return this.getCategories({ isFixed: true });
    }

    @computed public get fixedCategoriesVisibleInNavigation(): Array<CategoryType> {
        return this.getCategories({ isFixed: true, isVisibleInNavigation: true });
    }

    @computed public get customCategories(): Array<CategoryType> {
        return this.getCategories({ isFixed: false });
    }

    @computed public get customCategoriesVisibleInNavigation(): Array<CategoryType> {
        return this.getCategories({ isFixed: false, isVisibleInNavigation: true });
    }

    @computed public get customCategoriesVisibleInAllGames(): Array<CategoryType> {
        return this.getCategories({ isFixed: false, isVisibleInAllGames: true });
    }

    @computed public get getUserFavouriteGames(): Array<CasinoGameModelExt> {
        const favouriteUserGames = this.data.contextModel.favouriteGames.getReady()?.game_ids ?? [];
        return this.data.allGames.filter((game) => favouriteUserGames.includes(game.id));
    }
}
