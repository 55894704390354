import styled from '@emotion/styled';

import { Button as BaseButton } from 'src/domains/layouts/webview/components/common/button/Button';

export const Details = styled.div`
    font-size: 1.2rem;
`;

export const DetailRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
`;

export const DetailLabel = styled.div`
    flex: 1;
`;

export const DetailValue = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 2;
    gap: 4px;
`;

export const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-top: 12px;
`;

export const Button = styled(BaseButton)`
    flex: 1;
`;
