import { UniverseType } from 'src_common/common/universe';
import { getGlobalTranslateFunction, MESSAGES } from './errors';

const MAKE_DEPOSIT_AFTER_FUND_INFO_TICK = 'Deposit is not allowed when box regarding customer funds remains unticked';

const errorWrapper = (fun: () => string | undefined): string | undefined => {
    try {
        return fun();
    } catch (err) {
        console.warn(err);
    }
};

function getMessage(universe: UniverseType, error: string | undefined): string | undefined {
    //@ts-expect-error
    const value: createGetTranslation | undefined = MESSAGES(universe)[error];
    return value === undefined ? undefined : value(getGlobalTranslateFunction());
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getErrorMessage(universe: UniverseType, error: any): string {
    //temporary fix for withdraw error msg - its very wrong and ugly! ;O
    try {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (JSON.parse(error)) {
            const newErrMsg = JSON.parse(error);
            if (newErrMsg.errors[0].code === 'withdrawable-balance-at-least') {
                error = JSON.parse(error).errors[0].debugDetails;
            }
        }
    } catch (e) {
        // console.warn('getErrorMessage', e);
    }

    if (typeof error === 'string') {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return getMessage(universe, error) || error;
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (error && error._error) {
        let msg: string = getErrorMessage(universe, error.message);

        for (const param of Object.keys(error.params)) {
            msg = msg.split(`%${param}`).join(error.params[param]);
        }

        return msg;
    }

    const code = errorWrapper(() => String(error.code));
    const semi = errorWrapper(() => `${String(error.field)}:${code ?? ''}`);
    const semi2 = errorWrapper(() => `${String(error.resource)}:${code ?? ''}`);
    const full = errorWrapper(() => `${String(error.resource)}:${semi ?? ''}`);

    return (
        getMessage(universe, full) ??
        getMessage(universe, semi2) ??
        getMessage(universe, semi) ??
        getMessage(universe, code) ??
        full ??
        '--- unknown error ---'
    );
}

/**
 * @deprecated - Do not use this function, here are the dragons
 */
// FIXME: temporary solution, change when errors will be more standarized
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getError(universe: UniverseType, error: any): string | undefined {
    // It's React element, do not translate it
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (error && error.$$typeof) {
        return error;
    }
    const message = getErrorMessage(universe, error);

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (/You have to confirm that you know about ringfenced funds./i.exec(message)) {
        return MAKE_DEPOSIT_AFTER_FUND_INFO_TICK;
    }
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (/Your account is suspended/i.exec(message)) {
        const getTranslation = getGlobalTranslateFunction();
        return getTranslation('login.errors.suspended', 'Your account is suspended');
    }

    return message;
}
