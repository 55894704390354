import styled from '@emotion/styled';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons';
import { withConfig } from 'src/withConfig';

export const TooltipContainer = styled('div')`
    position: relative;

    .tooltip-content {
        visibility: hidden;
    }

    .tooltip-triangle {
        visibility: hidden;
    }

    &.hover-trigger:hover .tooltip-content,
    &.hover-trigger:hover .tooltip-triangle {
        visibility: visible;
    }

    &.click-trigger .tooltip-content-visible,
    &.click-trigger .tooltip-triangle-visible {
        visibility: visible;
    }
`;

export const IconContainer = styled('div')`
    display: flex;
    align-items: center;
    max-width: 14px;
`;

export const Icon = withConfig((theme) => styled(InfoIcon)`
    height: 16px;
    fill: ${theme.star.accountDrawer.bgColorSecondary};
`);

export const TooltipTriangle = withConfig((theme) => styled('div')`
    position: absolute;
    top: 20px;
    width: 0;
    height: 0;
    margin-left: 4px;
    margin-right: 16px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    z-index: ${theme.star.zIndexLevelBase.baseUp};
    border-bottom: 15px solid ${theme.star.tooltip.bgColor};
    visibility: hidden;
`);

export const TooltipContent = withConfig((theme) => styled('div')`
    position: absolute;
    top: 35px;
    width: 343px;
    transform: translate(-150px, 0);
    background: ${theme.star.tooltip.bgColor};
    color: ${theme.star.tooltip.txtColor};
    font-size: ${theme.star.fontSize.small};
    padding: 2px;
    z-index: ${theme.star.zIndexLevelBase.baseUp};
    visibility: hidden;
    text-align: center;
`);
