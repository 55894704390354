import styled from '@emotion/styled';
import { FreeBetIcon } from 'src/domains/layouts/shared/assets/icons';
import { withConfig } from 'src/withConfig';

export const Tabs = styled.div`
    display: flex;
    margin-bottom: 24px;
`;

interface TabButtonProps {
    isActive: boolean;
}

export const CreditsTabWrapper = styled.div`
    padding: 16px;
`;

export const TabButton = withConfig(
    (theme) => styled.button<TabButtonProps>`
        padding: 0 12px;
        margin: 0 4px 0 0;
        cursor: pointer;
        border: 1px solid #ffcc00;
        background-color: ${({ isActive }) => (isActive ? '#FFCC00' : '#ffffff')};
        color: ${({ isActive }) => (isActive ? '#111111' : '#FFCC00')};
        font-weight: ${theme.star.fontWeight.bold};
        font-size: ${theme.star.fontSize.small};
        transition: background-color 0.3s;
        height: 32px;
    `
);

export const TabContent = styled.div``;

export const TotalAmount = withConfig(
    (theme) => styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        font-size: ${theme.star.fontSize.xRegular};
        line-height: 1.4285;
    `
);

export const FreeBetIconWrapped = styled(FreeBetIcon)`
    width: 16px;
    height: 16px;
`;

export const AmountWrapper = styled('span')`
    display: flex;
    align-items: center;
    gap: 6px;
`;
