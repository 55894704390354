import { PlaceBetBetsType } from 'src/domains/sportsbook/betting/betting/postPlaceBet';

export interface ArrayBetsType {
    betId: number;
    type: string;
    totalStake: string;
    totalStakeCurrency: string;
    legs: Array<{
        marketId?: string | number;
        marketName?: string;
        selectionId?: string | number;
        selectionName?: string;
        betEventId?: string | number;
        betEventName?: string;
        price?: number;
        sportId?: number | string;
        sportName?: string;
    }>;
    isBoostVisible: boolean;
    isBoosted: boolean | undefined;
}

export const parsePlaceBetLegs = (legs: PlaceBetBetsType[number]['legs']): ArrayBetsType['legs'] =>
    legs.map((singleLeg) => {
        const { event, price } = singleLeg;
        const defaultLeg = {
            betEventName: event.name,
            price: price?.d,
        };
        switch (singleLeg.type) {
            case 'standard': {
                const { market, selection, sport } = singleLeg;
                return {
                    ...defaultLeg,
                    marketId: market.id,
                    marketName: market.name,
                    selectionId: selection.id,
                    selectionName: selection.name,
                    sportName: sport.name,
                    sportId: sport.id,
                };
            }
            case 'empty': {
                const { selection, sport } = singleLeg;
                return {
                    ...defaultLeg,
                    selectionName: selection.name,
                    sportId: sport?.id,
                };
            }
            case 'rab': {
                const { sport, event } = singleLeg;
                const betEventId =
                    event.eventId !== null && event.eventId !== undefined ? event.eventId.toString() : undefined;
                return {
                    ...defaultLeg,
                    betEventId: betEventId,
                    sportName: sport !== null && sport !== undefined && 'name' in sport ? sport.name : undefined,
                    sportId: sport?.id,
                };
            }
        }
    });
