import * as React from 'react';
import styled from '@emotion/styled';

const SvgElement = styled('svg')`
    height: auto;
`;

export const FreeSpinIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, onClick, ...props }) => {
    return (
        <SvgElement
            className={className}
            clipRule='evenodd'
            fill='currentColor'
            fillRule='evenodd'
            width='1em'
            height='1em'
            onClick={onClick}
            strokeLinejoin='round'
            strokeMiterlimit='2'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10.6882 4.74273C10.4641 4.0864 9.53586 4.0864 9.31173 4.74272L8.26402 7.81082C8.19125 8.02391 8.02389 8.19127 7.8108 8.26404L4.7427 9.31175C4.08638 9.53588 4.08638 10.4641 4.7427 10.6883L7.8108 11.736C8.02389 11.8087 8.19125 11.9761 8.26402 12.1892L9.31173 15.2573C9.53586 15.9136 10.4641 15.9136 10.6882 15.2573L11.7359 12.1892C11.8087 11.9761 11.9761 11.8087 12.1892 11.736L15.2573 10.6883C15.9136 10.4641 15.9136 9.53588 15.2573 9.31175L12.1892 8.26404C11.9761 8.19127 11.8087 8.02391 11.7359 7.81082L10.6882 4.74273Z'
                fill='currentColor'
            />
        </SvgElement>
    );
};
