import React from 'react';

import { Bonus, Status } from 'src/domains/players/state/BonusState';
import { observer } from 'mobx-react-lite';

import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { FreeSpinDetails } from './FreeSpinDetails/FreeSpinDetails';
import { BonusCardButton, BonusListWrapper } from '../../BonusCard/BonusCard.styled';
import { BonusCard } from '../../BonusCard';

export const FreeSpins = observer(({ items }: FreeSpinsProps) => {
    const appState = useAppStateContext();
    const {
        appLayoutsState: { starRouter },
        appPlayersState: { bonusState },
    } = appState;

    const handleViewTerms = (item: Bonus) => {
        bonusState.setFreeSpinDescription(item.campaignDescription);
        starRouter.redirectToBonusTermsAndConditions();
    };

    return (
        <BonusListWrapper>
            {items.map((item, index) => (
                <BonusCard
                    key={index}
                    item={item}
                    actionButtons={
                        <>
                            <BonusCardButton
                                size='small'
                                version='tertiary-light'
                                onClick={() => handleViewTerms(item)}
                                disabled={item.status !== Status.Active}
                            >
                                <I18n
                                    langKey='account.freeSpins.button.viewTerms'
                                    defaultText='View Terms'
                                />
                            </BonusCardButton>
                            <BonusCardButton
                                size='small'
                                version='primary'
                                onClick={() => starRouter.redirectToCasinoPage()}
                                disabled={item.status !== Status.Active}
                            >
                                <I18n
                                    langKey='account.freeSpins.button.playHere'
                                    defaultText='Play Here'
                                />
                            </BonusCardButton>
                        </>
                    }
                >
                    <FreeSpinDetails item={item} />
                </BonusCard>
            ))}
        </BonusListWrapper>
    );
});

type FreeSpinsProps = {
    items: Bonus[];
};
