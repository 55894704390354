import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Bonus, Status } from 'src/domains/players/state/BonusState';
import { FreeBetDetails } from './FreeBetDetails';
import { BonusCardButton, BonusListWrapper } from '../../BonusCard/BonusCard.styled';
import { BonusCard } from '../../BonusCard';

export const FreeBets = observer(({ items }: FreeBetsProps) => {
    const appState = useAppStateContext();
    const {
        appLayoutsState: { starRouter, sportsList },
    } = appState;

    const sports = sportsList.translatedSports;

    const sportNamesById = useMemo(() => {
        return sports.reduce<Record<string, string>>(
            (acc, sport) => ({
                ...acc,
                [sport.id]: sport.label,
            }),
            {}
        );
    }, [sports]);

    return (
        <BonusListWrapper>
            {items.map((item, index) => (
                <BonusCard
                    key={index}
                    item={item}
                    actionButtons={
                        <BonusCardButton
                            onClick={starRouter.redirectToHomepage}
                            disabled={item.status !== Status.Active}
                            size='small'
                        >
                            <I18n
                                langKey='account.bonus.button.bet-now'
                                defaultText='Bet Now'
                            />
                        </BonusCardButton>
                    }
                >
                    <FreeBetDetails
                        item={item}
                        sports={sportNamesById}
                    />
                </BonusCard>
            ))}
        </BonusListWrapper>
    );
});

type FreeBetsProps = {
    items: Bonus[];
};
