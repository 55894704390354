import { sortBy } from 'lodash';
import { action, computed, observable, makeObservable } from 'mobx';
import { PriceResponseItem } from 'src/domains/sportsbook/betting/state/rabState/rabItemState/PriceResponseItem';
import { SelectMarket, RabStatusType } from 'src/domains/sportsbook/betting/state/rabState/rabItemState/SelectMarket';
import {
    MarketRabView,
    RabOfferItemType,
    RabPostBodyItemType,
} from 'src/domains/sportsbook/betting/state/rabState/Types';
import { RabMarketsModel } from 'src_common/common/websocket2/models/RabMarketsModel';
import { RabBetType } from 'src/domains/sportsbook/betting/betting/postPlaceBet';
import * as uuid from 'uuid';
import { RabPriceError, RabPriceSuccess, RabPriceSuspended } from 'src/api/config/eventsRab/rabGetPrice';
import { splitMarketNameForSort } from 'src/domains/sportsbook/betting/state/rabState/splitMarketNameForSort';
import { RabMarketSelectionType, RabMarketType } from 'src_common/common/websocket2/modelsApi/RabMarket';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { ChannelType } from 'src/domains/sportsbook/betting/betting/types';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { BetslipRabId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import { StakeInputState2 } from 'src/domains/sportsbook/betting/betSlipState/stakeInputState/StakeInputState2';
import { FreeBetCreditsType, FreeBetType } from '../../betSlipState/BetSlipSheredTypes';

const filterCorrectScoreSelection = (selectionItem: RabMarketSelectionType): boolean => {
    const { away, home } = selectionItem.selectionType;
    if (away === null || away === undefined || home === null || home === undefined) {
        return false;
    }
    // Temporary change - waiting for BE
    if (away < 8 && home < 8) {
        return true;
    }

    return false;
};

const filterMarketsByTotalGoals = (item: RabMarketType): boolean => {
    if (item.name.includes('Total goals U/O') === true && item.line !== null && item.line !== undefined) {
        return item.line < 8;
    }
    return true;
};

interface RabSelectionsType {
    name: string;
    id: string;
    home: number | undefined | null;
    away: number | undefined | null;
    isSelect: boolean;
    team: string | undefined | null;
    marketName: string | null | undefined;
}

export class RabItemState {
    @observable.ref private select: SelectMarket;
    @observable private inputFieldValue: Amount = new Amount('0');

    public readonly stakeInput: StakeInputState2;

    public readonly id: BetslipRabId;
    public readonly eventId: EventId;

    public readonly uuid: string = uuid.v4();

    public constructor(
        private readonly sdkCustomer: SdkCustomer,
        private readonly configComponents: ConfigComponents,
        eventId: EventId,
        private readonly languagesState: LanguagesState,
        private readonly getChannel: () => ChannelType,
        private readonly decimalLength: number,
        private readonly getRabFreeBets: (id: string) => FreeBetType[],
        private readonly getSelectedFreeBets: (id: string) => FreeBetType[],
        private readonly getSelectedFreeBetCredits: (id: string, stake: number) => FreeBetCreditsType[]
    ) {
        makeObservable(this);
        this.eventId = eventId;

        this.id = new BetslipRabId(this.eventId, () => this);

        this.select = new SelectMarket(this.languagesState, eventId, () => this.markets);
        this.stakeInput = new StakeInputState2(this.decimalLength, this.setInputField);
    }

    @computed public get freeBets(): FreeBetType[] {
        return this.getRabFreeBets(this.uuid);
    }

    public get currentPrice(): PriceResponseItem {
        return this.select.price;
    }

    public acceptChanges(): void {
        this.select.acceptChanges();
    }

    @computed public get selectionChanged(): boolean {
        return this.select.selectionChanged;
    }

    @computed public get selectionChangedDown(): boolean {
        return this.select.selectionChangedDown;
    }

    @computed public get markets(): RabMarketsModel | null {
        return this.eventId.getRabModel();
    }

    @action public addSelection(market: string, selection: string): void {
        this.select.addSelection(market, selection);
    }

    @action public removeSelection(market: string, selection: string): void {
        this.select.removeSelection(market, selection);
    }

    public hasSelect(market: string, selection: string): boolean {
        return this.select.hasSelect(market, selection);
    }

    public get inputField(): Amount {
        return this.inputFieldValue;
    }

    public get platformId(): string | null {
        const eventModel = this.eventId.getEventModel();

        if (eventModel === null) {
            console.error(`Missing event ${this.eventId.toOldId()}`);
            return null;
        }

        return eventModel.rabId;
    }

    @action public setInputField = (value: Amount): void => {
        this.inputFieldValue = value;
    };

    @computed public get rabPrice(): Amount {
        return this.inputFieldValue;
    }

    public getSelectionsForView(item: RabMarketType): Array<RabMarketSelectionType> {
        if (item.name === 'Correct score' || item.name === '2nd half score' || item.name === '1st half score') {
            return item.selections.filter(filterCorrectScoreSelection);
        }

        return item.selections;
    }

    public getSelectionsForMarkets(item: RabMarketType): RabSelectionsType[] {
        return this.getSelectionsForView(item).map((selectionItem) => ({
            name: selectionItem.name,
            id: selectionItem.selectionType.id ?? '',
            home: selectionItem.selectionType.home,
            away: selectionItem.selectionType.away,
            isSelect: this.hasSelect(item.name, selectionItem.name),
            team: selectionItem.selectionType.team,
            marketName: item.scorerOrder,
        }));
    }

    @computed public get marketsForViewInner(): Array<MarketRabView> {
        const markets = this.markets;

        if (markets === null) {
            return [];
        }

        // Temoporary change - waiting for BE
        return markets.markets.filter(filterMarketsByTotalGoals).map((item) => ({
            name: item.name,
            selections: this.getSelectionsForMarkets(item),
            displayOrder: item.viewDetails.displayOrder,
            viewDetails: {
                selectionDisplayType: item.viewDetails.selectionDisplayType,
            },
            templateType: item.marketTemplateType,
        }));
    }

    @computed public get marketsForView(): Array<MarketRabView> {
        const markets = this.marketsForViewInner.concat([]);

        return sortBy(
            markets,
            (item: MarketRabView) => item.displayOrder,
            (item: MarketRabView) => splitMarketNameForSort(item.name).name,
            (item: MarketRabView) => splitMarketNameForSort(item.name).value
        );
    }

    @computed public get rabOffer(): Array<RabOfferItemType> {
        const out: Array<RabOfferItemType> = [];
        for (const market of this.marketsForView) {
            for (const selectionItem of market.selections) {
                if (selectionItem.isSelect) {
                    out.push({
                        market: market.name,
                        selection: selectionItem.name,
                    });
                }
            }
        }
        return out;
    }

    @computed public get currentPriceResponse(): null | RabPriceError | RabPriceSuccess | RabPriceSuspended {
        return this.currentPrice.price;
    }

    @computed public get rabStatus(): RabStatusType {
        return this.select.rabStatus;
    }

    @computed public get postBody(): Array<RabPostBodyItemType> {
        return this.select.postBody;
    }

    public getRabBet(payout: Amount | null): RabBetType | null {
        const eventId = this.eventId;

        const platformObjectId = this.platformId;
        if (platformObjectId === null) {
            return null;
        }

        const stakePerLine = this.inputField;
        const sportType = 'football'; //temporary
        const rabPrice = this.currentPriceResponse;

        if (rabPrice === null || rabPrice.type === 'error' || rabPrice.type === 'suspended') {
            return null;
        }
        const fractional = rabPrice.data.priceFractional;
        const userIp = this.sdkCustomer.session.ipUser;

        return {
            type: 'RAB',
            stakePerLine: stakePerLine.value,
            eachWay: false,
            channel: this.getChannel(),
            platformId: platformObjectId,
            //TODO: roundDown to remove after fix BE service. Currently, we receive values like 7.123(99)
            payout: payout === null ? null : this.configComponents.precision.valueOldFormat(payout.roundDown(2)),
            correlationId: this.uuid,
            ip: userIp ?? '',
            legs: [
                {
                    type: 'rab',
                    priceType: 'fp',
                    channel: this.getChannel(),
                    sport: {
                        id: sportType,
                    },
                    event: {
                        id: eventId.toOldId(),
                        externalId: platformObjectId,
                    },
                    selections: this.postBody,
                    price: {
                        d: rabPrice.data.price,
                        f: fractional,
                    },
                },
            ],
            freeBets: this.getSelectedFreeBets(this.uuid),
            freebetCredits: this.getSelectedFreeBetCredits(this.uuid, parseFloat(stakePerLine.value) * 100),
        };
    }
}
