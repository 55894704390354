import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { FiltersType } from 'src/domains/layouts/webview/components/filters/Filters';
import { TransactionParamsType } from './types';
import { ConfigType } from 'src/domains/layouts/config/features/types';

export const getFilters = (
    setFilter: (filter: TransactionParamsType) => void,
    language: LanguagesState,
    config: ConfigType
): Array<FiltersType> => {
    const transactionHistoryTabs: FiltersType[] = [
        {
            key: 'all',
            label: language.getTranslation('account.transaction-history.everything.label', 'Everything'),
            onClick: () => setFilter('all'),
        },
        {
            key: 'deposit',
            label: language.getTranslation('account.transaction-history.deposit.label', 'Deposit'),
            onClick: () => setFilter('deposit'),
        },
        {
            key: 'withdrawal',
            label: language.getTranslation('account.transaction-history.withdraw.label', 'Withdraw'),
            onClick: () => setFilter('withdrawal'),
        },
        {
            key: 'bet',
            label: language.getTranslation('account.transaction-history.bet.label', 'Bets'),
            onClick: () => setFilter('bet'),
        },
    ];

    if (config.headerLinks.includes('casino')) {
        transactionHistoryTabs.push({
            key: 'casino-wager;casino-generic',
            label: language.getTranslation('account.transaction-history.casino-wager.label', 'Casino'),
            onClick: () => setFilter('casino-wager;casino-generic'),
        });
    }

    if (config.headerLinks.includes('live-casino')) {
        transactionHistoryTabs.push({
            key: 'live-casino-wager;live-casino-generic',
            label: language.getTranslation('account.transaction-history.live-casino-wager.label', 'Live Casino'),
            onClick: () => setFilter('live-casino-wager;live-casino-generic'),
        });
    }

    if (config.headerLinks.includes('virtuals')) {
        transactionHistoryTabs.push({
            key: 'virtualsports-wager',
            label: language.getTranslation('account.transaction-history.virtual-sports-wager.label', 'Virtual Sports'),
            onClick: () => setFilter('virtualsports-wager'),
        });
    }

    if (config.headerLinks.includes('bingo')) {
        transactionHistoryTabs.push({
            key: 'bingo-wager;bingo-wager-rollback;bingo-result',
            label: language.getTranslation('account.transaction-history.bingo.label', 'Bingo'),
            onClick: () => setFilter('bingo-wager;bingo-wager-rollback;bingo-result'),
        });
    }
    // PGDEV-94 - Hide Net Deposit
    // transactionHistoryTabs.push({
    //     key: 'net-deposit',
    //     label: language.getTranslation('account.transaction-history.net-deposit.label', 'Net Deposit'),
    //     onClick: () => setFilter('net-deposit'),
    // });

    return transactionHistoryTabs;
};
