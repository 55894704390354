import { Status } from 'src/domains/players/state/BonusState';

export type CreditsParamsType = 'free-bets' | 'free-spins' | 'bonus-balance';

export enum TabType {
    FreeBets = 'freeBets',
    FreeSpins = 'freeSpins',
    // BonusBalance = 'bonusBalance',
}

export const TabTypeLabels: { [key in TabType]: string } = {
    [TabType.FreeBets]: 'Free Bets',
    [TabType.FreeSpins]: 'Free Spins',
    // [TabType.BonusBalance]: 'Bonus Balance',
};

export const StatusTypeLabels = {
    [Status.Active]: 'Active',
    [Status.Expired]: 'Expired',
    [Status.Canceled]: 'Canceled',
    [Status.Claimed]: 'Claimed',
    [Status.RolledBack]: 'Rolled Back',
};

export interface BetStatusProps {
    status: Status;
}
