import styled from '@emotion/styled';

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
`;

export const Th = styled.th<{ colSize: string }>`
    background: #f5f5f5;
    padding: 8px;
    text-align: left;
    border-bottom: 2px solid #ddd;
    width: ${(props) => props.colSize};
`;

export const Tr = styled.tr<{ isLast: boolean }>`
    ${(props) => !props.isLast && 'border-bottom: 1px solid #ddd;'}
`;

export const Td = styled.td<{ colSize: string }>`
    padding: 8px;
    word-break: break-word;
    width: ${(props) => props.colSize};
`;
