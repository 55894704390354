import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    AmountValue,
    AmountWrapper,
    Arrow,
    BoxItem,
    Container,
    FreeBetIconWrapped,
    FreeSpinIconWrapped,
    Title,
} from './AccountFreeBet.styled';
import { TabType } from '../../CreditsTab/types';

export const AccountFreeBet = observer(() => {
    const {
        appLayoutsState: { starRouter },
        appPlayersState: { usersState, bonusState },
    } = useAppStateContext();

    useEffect(() => {
        bonusState.refreshBalance();
    }, [bonusState]);

    const handleRedirect = (tab: TabType) => {
        bonusState.setSelectedTab(tab);
        starRouter.redirectToCredits();
    };

    return (
        <Container>
            {Number(bonusState.freeBetBalance.value) > 0 && (
                <BoxItem onClick={() => handleRedirect(TabType.FreeBets)}>
                    <Title>
                        <I18n
                            langKey='account.summary.balance.free-bet.title'
                            defaultText='Free Bets'
                        />

                        <Arrow position='right' />
                    </Title>

                    <AmountWrapper>
                        <FreeBetIconWrapped />
                        <AmountValue>{usersState.money(bonusState.freeBetBalance)}</AmountValue>
                    </AmountWrapper>
                </BoxItem>
            )}

            {bonusState.freeSpinBalance > 0 && (
                <BoxItem onClick={() => handleRedirect(TabType.FreeSpins)}>
                    <Title>
                        <I18n
                            langKey='account.summary.balance.free-spins.title'
                            defaultText='Free Spins'
                        />

                        <Arrow position='right' />
                    </Title>

                    <AmountWrapper>
                        <FreeSpinIconWrapped />
                        <AmountValue>{bonusState.freeSpinBalance}</AmountValue>
                    </AmountWrapper>
                </BoxItem>
            )}
        </Container>
    );
});
