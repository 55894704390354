import React, { useMemo, useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { EligibleDetailsWrapper, EligibleDetailsItem } from './EligibleDetailsList.styled';
import { HierarchyPopup } from '../HierarchyPopup';
import { HierarchyTable } from '../HierarchyTable';
import { mapDetailsToHierarchy } from '../../../../utils/mapDetailsToHierarchy';
import { Bonus, DetailType } from 'src/domains/players/state/BonusState';
import { BadgeDropdownList, BadgeItem } from '../../../BadgeDropdownList';
import { BonusCardLabel } from '../../../BonusCard/BonusCard.styled';

const ELIGIBLE_KEYS = ['sport', 'competition', 'event', 'market', 'selection'] as const;

export type EligibleKey = (typeof ELIGIBLE_KEYS)[number];
export type HierarchyData = Partial<Record<DetailType, string>>;

type AssignedCategory = { dataKey: EligibleKey; hierarchy: Record<string, HierarchyData> };

export const EligibleDetailsList = ({ item }: { item: Bonus }) => {
    const [popupData, setPopupData] = useState<HierarchyData | null>(null);

    const handleOpenPopup = ({ data }: BadgeItem<HierarchyData>) => {
        setPopupData(data);
    };
    const handleClosePopup = () => setPopupData(null);

    const hierarchyData = useMemo(() => mapDetailsToHierarchy(item), [item]);

    const assignCategories = (
        eligibleKeys: readonly EligibleKey[],
        hierarchyData: Record<string, HierarchyData>
    ): AssignedCategory[] => {
        return eligibleKeys.reduce<AssignedCategory[]>((result, dataKey) => {
            const hierarchy = Object.fromEntries(
                Object.entries(hierarchyData).filter(([fullKey]) => fullKey.startsWith(`${dataKey}|`))
            );

            if (Object.keys(hierarchy).length > 0) {
                result.push({ dataKey, hierarchy });
            }

            return result;
        }, []);
    };

    const eligibleDetails = useMemo(() => assignCategories(ELIGIBLE_KEYS, hierarchyData), [hierarchyData]);
    return (
        <EligibleDetailsWrapper>
            {eligibleDetails.map(({ dataKey, hierarchy }) => {
                return (
                    <EligibleDetailsItem key={dataKey}>
                        <BonusCardLabel>{labelComponents[dataKey]}</BonusCardLabel>
                        <BadgeDropdownList
                            items={mapEligibleDetailsToBadgeItems(hierarchy)}
                            onBadgeClick={handleOpenPopup}
                            renderBadge={(item) => (
                                <div style={{ cursor: 'pointer', textDecoration: 'underline dashed #999' }}>
                                    {item.label}
                                </div>
                            )}
                        />
                    </EligibleDetailsItem>
                );
            })}

            {popupData != null && (
                <HierarchyPopup
                    onClose={handleClosePopup}
                    title='Eligible Details'
                >
                    <HierarchyTable data={popupData} />
                </HierarchyPopup>
            )}
        </EligibleDetailsWrapper>
    );
};

const labelComponents = {
    sport: (
        <I18n
            langKey='account.bonus.details.label.eligible-sports'
            defaultText='Eligible Sports:'
        />
    ),
    competition: (
        <I18n
            langKey='account.bonus.details.label.eligible-competitions'
            defaultText='Eligible Competitions:'
        />
    ),
    event: (
        <I18n
            langKey='account.bonus.details.label.eligible-events'
            defaultText='Eligible Events:'
        />
    ),
    market: (
        <I18n
            langKey='account.bonus.details.label.eligible-markets'
            defaultText='Eligible Markets:'
        />
    ),
    selection: (
        <I18n
            langKey='account.bonus.details.label.eligible-selections'
            defaultText='Eligible Selections:'
        />
    ),
};

const mapEligibleDetailsToBadgeItems = (items: Record<string, HierarchyData>): BadgeItem<HierarchyData>[] => {
    return Object.entries(items).map(([key, value]) => ({
        id: key,
        label: key.split('|')[1] ?? key,
        data: value,
    }));
};
