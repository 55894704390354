import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { useAppStateContext } from 'src/appState/AppState';
import { getBetsTranslation } from 'src/domains/layouts/shared/translations/bets';
import { Bonus } from 'src/domains/players/state/BonusState';
import { EligibleDetailsList } from '../EligibleDetailsList';
import { BadgeDropdownList, BadgeItem } from '../../../BadgeDropdownList';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { BonusCardLabel } from '../../../BonusCard/BonusCard.styled';

export const FreeBetDetails = observer(({ item }: { item: Bonus; sports: Record<string, string> }) => {
    const appState = useAppStateContext();

    const eligibleBetTypes = useMemo(() => {
        const items = getUsageConditionsValue(item.usageConditions, 'bet-type') ?? [];

        return Array.isArray(items)
            ? items.map((betTypeId) => getBetsTranslation(appState, betTypeId) ?? betTypeId)
            : [];
    }, [appState, item.usageConditions]);

    const minOdds = getUsageConditionsValue(item.usageConditions, 'bet-min-odds');

    return (
        <>
            {eligibleBetTypes.length > 0 && (
                <>
                    <BonusCardLabel>
                        <I18n
                            langKey='account.bonus.details.label.bet-type'
                            defaultText='Bet Type:'
                        />
                    </BonusCardLabel>{' '}
                    <BadgeDropdownList items={mapStringsToBadgeItems(eligibleBetTypes)} />
                </>
            )}

            <EligibleDetailsList item={item} />

            {minOdds !== undefined && (
                <>
                    <BonusCardLabel>
                        <I18n
                            langKey='account.bonus.details.label.bet-min-odds'
                            defaultText='Bet Min Odds:'
                        />
                    </BonusCardLabel>{' '}
                    {minOdds}
                </>
            )}
        </>
    );
});

const getUsageConditionsValue = (
    usageConditions: Bonus['usageConditions'],
    type: string
): number | string[] | undefined => {
    return usageConditions.find((usageCondition) => usageCondition.type === type)?.value;
};

const mapStringsToBadgeItems = (items: string[]): BadgeItem[] => {
    return items.map((item) => ({
        id: item,
        label: item,
    }));
};
