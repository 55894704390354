/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React, { useCallback, useMemo } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Bonus } from 'src/domains/players/state/BonusState';
import { BetStatus } from '../BetStatus';
import { formatDate } from '../../utils/formatDate';
import {
    BonusCardHeader,
    BonusCard as BonusCardContainer,
    BonusCardTitleWrapper,
    BonusCardTitle,
    BonusCardLabel,
    BonusCardContent,
    BonusCardActions,
} from './BonusCard.styled';
import { useAppStateContext } from 'src/appState/AppState';
import { Amount } from 'src_common/common/amount/Amount';

export const BonusCard = ({ item, children, actionButtons }: BonusCardProps) => {
    const appState = useAppStateContext();
    const {
        appPlayersState: { usersState },
    } = appState;

    const isFreeSpins = item.bonusType === BonusType.FreeSpin;

    const getFormattedAmount = useCallback(
        (amount: string | number) => (isFreeSpins ? amount : usersState.money(new Amount(`${amount}`).div(100))),
        [isFreeSpins, usersState]
    );

    const formattedAmount = useMemo(
        () => getFormattedAmount(item.currentAmount),
        [getFormattedAmount, item.currentAmount]
    );
    const totalAmount = useMemo(() => getFormattedAmount(item.totalAmount), [getFormattedAmount, item.totalAmount]);

    return (
        <BonusCardContainer>
            <BonusCardHeader>
                <BonusCardTitleWrapper>
                    <BonusCardTitle>
                        {formattedAmount} {bonusTypeLabels[item.bonusType as BonusType]}
                    </BonusCardTitle>

                    <BetStatus status={item.status} />
                </BonusCardTitleWrapper>

                <strong>
                    <BonusCardLabel>
                        <I18n
                            langKey='account.bonus.label.total-amount'
                            defaultText='Total Amount:'
                        />
                    </BonusCardLabel>{' '}
                    {totalAmount}
                </strong>

                <BonusCardLabel>
                    <I18n
                        langKey='account.bonus.label.expiry-date'
                        defaultText='Expiry on:'
                    />{' '}
                    {formatDate(item.expiryAt)}
                </BonusCardLabel>
            </BonusCardHeader>

            <BonusCardContent>{children}</BonusCardContent>

            <BonusCardActions>{actionButtons}</BonusCardActions>
        </BonusCardContainer>
    );
};

type BonusCardProps = {
    item: Bonus;
    children: React.ReactNode;
    actionButtons: React.ReactNode;
};

export enum BonusType {
    FreeBet = 'free-bet',
    FreeSpin = 'free-spin',
    BonusBalance = 'bonus-balance',
}

const bonusTypeLabels: Record<BonusType, JSX.Element> = {
    [BonusType.FreeBet]: (
        <I18n
            langKey='account.bonus.title.free-bet'
            defaultText='Free Bet'
        />
    ),
    [BonusType.FreeSpin]: (
        <I18n
            langKey='account.bonus.title.free-spins'
            defaultText='Free Spins'
        />
    ),
    [BonusType.BonusBalance]: (
        <I18n
            langKey='account.bonus.title.bonus-balance'
            defaultText='Bonus Balance'
        />
    ),
};
