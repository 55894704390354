import { MatchRouting } from './MatchRouting';
import { getParamNumber } from './parsers';

//............................................................................

type LoginRhsRouteType = {
    account: 'login';
    promo: string | null;
};

const tryLogin = (params: Record<string, string>): LoginRhsRouteType | null => {
    const { account, promo } = params;
    if (account === 'login') {
        return {
            account: 'login',
            promo: promo ?? null,
        };
    }

    return null;
};

//............................................................................

type SignupRhsRouteType = {
    account: 'signup';
    promo: string | null;
};

const trySignup = (params: Record<string, string>): SignupRhsRouteType | null => {
    const { account, promo } = params;
    if (account === 'signup') {
        return {
            account: 'signup',
            promo: promo ?? null,
        };
    }

    return null;
};

//............................................................................

// export type StaticType = 'free-bet-credits' | 'terms-and-conditions-promotion' | 'terms-and-conditions' | 'privacy-policy' | string

export type StaticRhsRouteType = {
    account: 'static';
    // static: StaticType,
    static: string;
    // parent: 'summary' | null
};

const tryStatic = (params: Record<string, string>): StaticRhsRouteType | null => {
    const { account } = params;
    const staticParam = params.static;

    if (account === 'static' && staticParam !== undefined) {
        return {
            account: 'static',
            static: staticParam,
        };
    }

    return null;
};

//............................................................................

type FinishKycRhsRouteType = {
    account: 'finish-kyc';
};

const tryFinishKyc = (params: Record<string, string>): FinishKycRhsRouteType | null => {
    const { account } = params;
    if (account === 'finish-kyc') {
        return {
            account: 'finish-kyc',
        };
    }

    return null;
};

//............................................................................

type GameInfoRhsRouteType = {
    account: 'game-info';
};

const tryGameInfo = (params: Record<string, string>): GameInfoRhsRouteType | null => {
    const { account } = params;
    if (account === 'game-info') {
        return {
            account: 'game-info',
        };
    }

    return null;
};

//............................................................................

type TopUpRhsRouteType = {
    account: 'top-up';
    amount: number | null;
};

const tryTopUp = (params: Record<string, string>): TopUpRhsRouteType | null => {
    const { account } = params;
    const amount = getParamNumber(params, 'amount');

    if (account === 'top-up') {
        return {
            account: 'top-up',
            amount,
        };
    }

    return null;
};

//............................................................................

type StaticResourceHeadlineCarouselPromoTermsRhsRouteType = {
    account: 'static-resource-headline-promo-terms';
    promoId: string | null;
};

const tryStaticResourceHeadlineCarouselPromoTerms = (
    params: Record<string, string>
): StaticResourceHeadlineCarouselPromoTermsRhsRouteType | null => {
    const { account, promoId } = params;

    if (account === 'static-resource-headline-promo-terms') {
        return {
            account: 'static-resource-headline-promo-terms',
            promoId: promoId ?? null,
        };
    }

    return null;
};

//............................................................................

type TermsAndConditionsCasinoBannerRhsRouteType = {
    account: 'terms-and-conditions-casino-banner';
    static: string | null;
    collection: string | null;
};

const tryTermsAndConditionsCasinoBanner = (
    params: Record<string, string>
): TermsAndConditionsCasinoBannerRhsRouteType | null => {
    const { account, collection } = params;
    const staticParam = params['static'];

    if (account === 'terms-and-conditions-casino-banner') {
        return {
            account: 'terms-and-conditions-casino-banner',
            collection: collection ?? null,
            static: staticParam ?? null,
        };
    }

    return null;
};

//............................................................................

type BettingRhsRouteType = {
    account: 'betting';
};

const tryBetting = (params: Record<string, string>): BettingRhsRouteType | null => {
    const { account } = params;
    if (account === 'betting') {
        return {
            account: 'betting',
        };
    }

    return null;
};

//............................................................................

type TraderChatRhsRouteType = {
    account: 'trader-chat';
};

const tryTrader = (params: Record<string, string>): TraderChatRhsRouteType | null => {
    const { account } = params;
    if (account === 'trader-chat') {
        return {
            account: 'trader-chat',
        };
    }

    return null;
};

//............................................................................

type ForgotPasswordRhsRouteType = {
    account: 'forgot-password';
};

const tryForgotPassword = (params: Record<string, string>): ForgotPasswordRhsRouteType | null => {
    const { account } = params;
    if (account === 'forgot-password') {
        return {
            account: 'forgot-password',
        };
    }

    return null;
};

//............................................................................

export type ResetPasswordRhsRouteType = {
    account: 'reset-password';
    token: string | null;
    email: string | null;
    isVerify: boolean | null;
    receivedVia: 'sms' | 'mail' | null;
};

const getResetPasswordReceivedVia = (value: string | undefined): 'sms' | 'mail' | null => {
    if (value === 'sms' || value === 'mail') {
        return value;
    }

    return null;
};

const checkIsVerify = (value: string | undefined): boolean => {
    return value === 'true';
};

const tryResetPassword = (params: Record<string, string>): ResetPasswordRhsRouteType | null => {
    const { account, token, email, isVerify, receivedVia } = params;

    if (account === 'reset-password') {
        return {
            account: 'reset-password',
            token: token ?? null,
            email: email ?? null,
            isVerify: checkIsVerify(isVerify),
            receivedVia: getResetPasswordReceivedVia(receivedVia),
        };
    }

    return null;
};

//............................................................................

type WalletRhsRouteType = {
    account: 'wallet';
};

const tryWallet = (params: Record<string, string>): WalletRhsRouteType | null => {
    const { account } = params;
    if (account === 'wallet') {
        return {
            account: 'wallet',
        };
    }

    return null;
};

//............................................................................

type SummaryRhsRouteType = {
    account: 'summary';
};

const trySummary = (params: Record<string, string>): SummaryRhsRouteType | null => {
    const { account } = params;
    if (account === 'summary') {
        return {
            account: 'summary',
        };
    }

    return null;
};

//............................................................................

type LimitsRhsRouteType = {
    account: 'limits';
};

const tryLimits = (params: Record<string, string>): LimitsRhsRouteType | null => {
    const { account } = params;
    if (account === 'limits') {
        return {
            account: 'limits',
        };
    }

    return null;
};

//............................................................................

type CustomerFundsProtectionRhsRouteType = {
    account: 'customer-funds-protection';
};

const tryCustomerFundsProtection = (params: Record<string, string>): CustomerFundsProtectionRhsRouteType | null => {
    const { account } = params;
    if (account === 'customer-funds-protection') {
        return {
            account: 'customer-funds-protection',
        };
    }

    return null;
};

//............................................................................

type AddCreditRhsRouteType = {
    account: 'add-credit';
};

const tryAddCredit = (params: Record<string, string>): AddCreditRhsRouteType | null => {
    const { account } = params;
    if (account === 'add-credit') {
        return {
            account: 'add-credit',
        };
    }

    return null;
};

//............................................................................

type PersonalDetailsRhsRouteType = {
    account: 'personal-details';
};

const tryPersonalDetails = (params: Record<string, string>): PersonalDetailsRhsRouteType | null => {
    const { account } = params;
    if (account === 'personal-details') {
        return {
            account: 'personal-details',
        };
    }

    return null;
};

//............................................................................

type PreferencesRhsRouteType = {
    account: 'preferences';
};

const tryPreferences = (params: Record<string, string>): PreferencesRhsRouteType | null => {
    const { account } = params;
    if (account === 'preferences') {
        return {
            account: 'preferences',
        };
    }

    return null;
};

//............................................................................

type WithdrawRhsRouteType = {
    account: 'withdraw';
};

const tryWithdraw = (params: Record<string, string>): WithdrawRhsRouteType | null => {
    const { account } = params;
    if (account === 'withdraw') {
        return {
            account: 'withdraw',
        };
    }

    return null;
};

//............................................................................

type BetHistoryRhsRouteType = {
    account: 'bet-history';
};

const tryBetHistory = (params: Record<string, string>): BetHistoryRhsRouteType | null => {
    const { account } = params;
    if (account === 'bet-history') {
        return {
            account: 'bet-history',
        };
    }

    return null;
};

//............................................................................

type FreeBetsRhsRouteType = {
    account: 'credits';
};

const tryCredits = (params: Record<string, string>): FreeBetsRhsRouteType | null => {
    const { account } = params;
    if (account === 'credits') {
        return {
            account: 'credits',
        };
    }

    return null;
};

//............................................................................

type TransactionHistoryRhsRouteType = {
    account: 'transaction-history';
};

const tryTransactionHistory = (params: Record<string, string>): TransactionHistoryRhsRouteType | null => {
    const { account } = params;
    if (account === 'transaction-history') {
        return {
            account: 'transaction-history',
        };
    }

    return null;
};

//............................................................................

type TermsAndConditionsPromosRhsRouteType = {
    account: 'terms-and-conditions-promos';
    notificationId: string;
};

const tryTermsAndConditionsPromos = (params: Record<string, string>): TermsAndConditionsPromosRhsRouteType | null => {
    const { account, notificationId } = params;

    if (account === 'terms-and-conditions-promos' && notificationId !== undefined) {
        return {
            account: 'terms-and-conditions-promos',
            notificationId,
        };
    }

    return null;
};

//............................................................................

type StaticResourceHeroWidgetRhsRouteType = {
    account: 'static-resource-hero-widget';
    static: string;
};

const tryStaticResourceHeroWidget = (params: Record<string, string>): StaticResourceHeroWidgetRhsRouteType | null => {
    const { account } = params;
    const staticParam = params['static'];

    if (account === 'static-resource-hero-widget' && staticParam !== undefined) {
        return {
            account: 'static-resource-hero-widget',
            static: staticParam,
        };
    }

    return null;
};

//............................................................................

type StaticResourceCarouselPromoTermsRhsRouteType = {
    account: 'static-resource-carousel-promo-terms';
    promoId: number;
};

const tryStaticResourceCarouselPromoTerms = (
    params: Record<string, string>
): StaticResourceCarouselPromoTermsRhsRouteType | null => {
    const { account } = params;
    const promoId = getParamNumber(params, 'promoId');

    if (account === 'static-resource-carousel-promo-terms' && promoId !== null) {
        return {
            account: 'static-resource-carousel-promo-terms',
            promoId,
        };
    }

    return null;
};

//............................................................................

type TermsAndConditionsPromosSpecialRhsRouteType = {
    account: 'terms-and-conditions-promos-special';
    promo: string | null;
};

const tryTermsAndConditionsPromosSpecial = (
    params: Record<string, string>
): TermsAndConditionsPromosSpecialRhsRouteType | null => {
    const { account, promo } = params;
    if (account === 'terms-and-conditions-promos-special') {
        return {
            account: 'terms-and-conditions-promos-special',
            promo: promo ?? null,
        };
    }

    return null;
};

//............................................................................

type ResetPasswordPrivacyRhsRouteType = {
    account: 'reset-password-privacy';
    token: string | null;
    email: string | null;
    isVerify: boolean | null;
    receivedVia: 'sms' | 'mail' | null;
};

const tryResetPasswordPrivacy = (params: Record<string, string>): ResetPasswordPrivacyRhsRouteType | null => {
    const { account, email, token, isVerify, receivedVia } = params;
    if (account === 'reset-password-privacy') {
        return {
            account: 'reset-password-privacy',
            email: email ?? null,
            token: token ?? null,
            isVerify: checkIsVerify(isVerify),
            receivedVia: getResetPasswordReceivedVia(receivedVia),
        };
    }

    return null;
};

//............................................................................

type ResetPasswordTermsRhsRouteType = {
    account: 'reset-password-terms';
    token: string | null;
    email: string | null;
    isVerify: boolean | null;
    receivedVia: 'sms' | 'mail' | null;
};

const tryResetPasswordTerms = (params: Record<string, string>): ResetPasswordTermsRhsRouteType | null => {
    const { account, email, token, isVerify, receivedVia } = params;
    if (account === 'reset-password-terms') {
        return {
            account: 'reset-password-terms',
            email: email ?? null,
            token: token ?? null,
            isVerify: checkIsVerify(isVerify),
            receivedVia: getResetPasswordReceivedVia(receivedVia),
        };
    }

    return null;
};

//............................................................................

type ResetPasswordCookiesRhsRouteType = {
    account: 'reset-password-cookies';
    token: string | null;
    email: string | null;
    isVerify: boolean | null;
    receivedVia: 'sms' | 'mail' | null;
};

const tryResetPasswordCookies = (params: Record<string, string>): ResetPasswordCookiesRhsRouteType | null => {
    const { account, email, token, isVerify, receivedVia } = params;
    if (account === 'reset-password-cookies') {
        return {
            account: 'reset-password-cookies',
            email: email ?? null,
            token: token ?? null,
            isVerify: checkIsVerify(isVerify),
            receivedVia: getResetPasswordReceivedVia(receivedVia),
        };
    }

    return null;
};

//............................................................................

type VerifyAccountRhsRouteType = {
    account: 'verify-account';
};

const tryVerifyAccountRhs = (params: Record<string, string>): VerifyAccountRhsRouteType | null => {
    const { account } = params;
    if (account === 'verify-account') {
        return {
            account: 'verify-account',
        };
    }

    return null;
};

//............................................................................

type NavigationRhsRouteType = {
    account: 'navigation';
};

const tryNavigation = (params: Record<string, string>): NavigationRhsRouteType | null => {
    const { account } = params;
    if (account === 'navigation') {
        return {
            account: 'navigation',
        };
    }

    return null;
};

//............................................................................

type CloseAccountRhsRouteType = {
    account: null;
};

const tryCloseAccount = (params: Record<string, string | null>): CloseAccountRhsRouteType | null => {
    const { account } = params;
    if (account === null) {
        return {
            account: null,
        };
    }

    return null;
};

//............................................................................

type BonusTermsAndConditionsRhsRouteType = {
    account: 'bonus-terms-and-conditions';
};

const tryBonusTermsAndConditions = (params: Record<string, string>): BonusTermsAndConditionsRhsRouteType | null => {
    const { account } = params;
    if (account === 'bonus-terms-and-conditions') {
        return {
            account: 'bonus-terms-and-conditions',
        };
    }
    return null;
};

//............................................................................

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const tryMachAccountView = (params: Record<string, string>) => {
    const result = MatchRouting.create(params)
        .tryMatch(tryLogin)
        .tryMatch(trySignup)
        .tryMatch(tryStatic)
        .tryMatch(tryFinishKyc)
        .tryMatch(tryGameInfo)
        .tryMatch(tryTopUp)
        .tryMatch(tryStaticResourceHeadlineCarouselPromoTerms)
        .tryMatch(tryTermsAndConditionsCasinoBanner)
        .tryMatch(tryBetting)
        .tryMatch(tryTrader)
        .tryMatch(tryForgotPassword)
        .tryMatch(tryResetPassword)
        .tryMatch(tryWallet)
        .tryMatch(trySummary)
        .tryMatch(tryLimits)
        .tryMatch(tryCustomerFundsProtection)
        .tryMatch(tryAddCredit)
        .tryMatch(tryPersonalDetails)
        .tryMatch(tryPreferences)
        .tryMatch(tryWithdraw)
        .tryMatch(tryBetHistory)
        .tryMatch(tryCredits)
        .tryMatch(tryTransactionHistory)
        .tryMatch(tryTermsAndConditionsPromos)
        .tryMatch(tryStaticResourceHeroWidget)
        .tryMatch(tryStaticResourceCarouselPromoTerms)
        .tryMatch(tryTermsAndConditionsPromosSpecial)
        .tryMatch(tryResetPasswordPrivacy)
        .tryMatch(tryResetPasswordTerms)
        .tryMatch(tryResetPasswordCookies)
        .tryMatch(tryVerifyAccountRhs)
        .tryMatch(tryNavigation)
        .tryMatch(tryCloseAccount)
        .tryMatch(tryBonusTermsAndConditions)
        .get();

    return result;
};

export type RightHandSideViewType = NonNullable<ReturnType<typeof tryMachAccountView>>;
