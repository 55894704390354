import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { ssrReactLazy } from 'src_common/utils/Idle';
import { AppCasinoState, CasinoPageState } from 'src/domains/casino/state/AppCasino.state';
import { CollectionType } from 'src/domains/casino/types/types';
import { CasinoGames } from 'src/domains/casino/webview/CasinoGames/category-games/CasinoGames';
import { LazyLoadWrapper } from 'src/domains/casino/utils/lazyLoadWrapper';

const CasinoHome = ssrReactLazy(
    async () => (await import('src/domains/casino/webview/CasinoGames/home-content/CasinoHome')).CasinoHome
);

const LiveCasinoHome = ssrReactLazy(
    async () => (await import('src/domains/casino/webview/CasinoGames/home-content/LiveCasinoHome')).LiveCasinoHome
);

const VirtualGamesHomepage = ssrReactLazy(
    async () => (await import('src/domains/casino/webview/gamesOnHomepage/VirtualGamesHomepage')).VirtualGamesHomepage
);

interface HomeCollectionProps {
    appCasinoState: AppCasinoState;
    collection: CollectionType;
}

const HomeCollection = observer('HomeCollection', (props: HomeCollectionProps): JSX.Element | null => {
    const { appCasinoState, collection } = props;

    if (collection === 'casino') {
        return (
            <LazyLoadWrapper>
                <CasinoHome appCasinoState={appCasinoState} />
            </LazyLoadWrapper>
        );
    }

    if (collection === 'live-casino') {
        return (
            <LazyLoadWrapper>
                <LiveCasinoHome appCasinoState={appCasinoState} />
            </LazyLoadWrapper>
        );
    }

    return (
        <LazyLoadWrapper>
            <VirtualGamesHomepage appCasinoState={appCasinoState} />
        </LazyLoadWrapper>
    );
});

interface CasinoContentPropsType {
    appCasinoState: AppCasinoState;
    casinoPageState: CasinoPageState;
    collection: CollectionType;
}

export const CasinoContent = observer('CasinoContent', (props: CasinoContentPropsType): JSX.Element => {
    const { appCasinoState, casinoPageState, collection } = props;
    const { navigationState } = casinoPageState;

    if (navigationState.isHomeActive && navigationState.isSearchActive === false) {
        return (
            <HomeCollection
                appCasinoState={appCasinoState}
                collection={collection}
            />
        );
    }
    return (
        <CasinoGames
            appCasinoState={appCasinoState}
            gameModels={navigationState.gamesListToDisplay}
        />
    );
});
