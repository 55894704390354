/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { action, computed, observable } from 'mobx';
import { TrpcClient } from 'src/appState/TrpcClient';
import { Amount } from 'src_common/common/amount/Amount';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { TabType } from '../webview/components/Account/CreditsTab/types';

export class BonusState {
    private balanceData: Resource<Result<Balance> | null>;
    private bonusesData: Resource<Result<BonusResponse> | null>;

    @observable public selectedTab: TabType | null = null;
    @observable public selectedFreeSpinDescription: string | null = null;

    public constructor(private readonly trpc: TrpcClient) {
        this.balanceData = new Resource(async (): Promise<Result<Balance>> => {
            return await this.trpc.client.bonus.getBalance.query();
        });

        this.bonusesData = new Resource(async (): Promise<Result<BonusResponse>> => {
            return await this.trpc.client.bonus.getBonuses.query();
        });
    }

    private sortBonuses(bonuses: Bonus[]): Bonus[] {
        return bonuses.sort((prev, next) => {
            if (prev.status === Status.Active && next.status !== Status.Active) {
                return -1;
            } else if (prev.status !== Status.Active && next.status === Status.Active) {
                return 1;
            }

            return new Date(prev.expiryAt).getTime() - new Date(next.expiryAt).getTime();
        });
    }

    private getSortedBonuses<T extends keyof BonusResponse>(type: T): Bonus[] {
        const response = this.bonusesData.getReady();
        if (response == null || response.data == null) {
            return [];
        }

        return this.sortBonuses(response.data[type] ?? []);
    }

    private getBalanceAmount(type: 'free-bet' | 'free-spin'): Amount | number {
        const response = this.balanceData.getReady();
        const amount = response?.data?.[type]?.availableAmount ?? 0;

        return type === 'free-bet' ? new Amount(`${amount}`).div(100) : amount;
    }

    @computed public get freeBets(): Bonus[] {
        return this.getSortedBonuses('free-bet');
    }

    @computed public get freeSpins(): Bonus[] {
        return this.getSortedBonuses('free-spin');
    }

    @computed public get freeBetBalance(): Amount {
        return this.getBalanceAmount('free-bet') as Amount;
    }

    @computed public get freeSpinBalance(): number {
        return this.getBalanceAmount('free-spin') as number;
    }

    @computed public get isLoadingBonuses(): boolean {
        const response = this.bonusesData.getReady();

        return response === null;
    }

    @action public refreshBalance() {
        if (this.balanceData.get().type === 'ready') {
            void this.balanceData.refresh();
        }
    }

    @action public refreshBonuses() {
        if (this.bonusesData.get().type === 'ready') {
            void this.bonusesData.refresh();
        }
    }

    @action public setFreeSpinDescription(description: string | null) {
        this.selectedFreeSpinDescription = description;
    }

    @computed public get getSelectedFreeSpinDescription(): string | null {
        return this.selectedFreeSpinDescription;
    }

    @action public setSelectedTab(tab: TabType) {
        this.selectedTab = tab;
    }

    @computed public get getSelectedTab(): TabType | null {
        return this.selectedTab;
    }
}

export type Result<T> = {
    status: number;
    data?: T;
    type: string;
    error?: string;
};

export type Balance = {
    'free-bet'?: {
        universe: string;
        availableAmount: number;
    };
    'free-spin'?: {
        universe: string;
        availableAmount: number;
    };
};

export type BonusResponse = {
    'free-bet'?: Bonus[];
    'free-spin'?: Bonus[];
};

export type Bonus = {
    universe: string;
    accountId: number;
    bonusId: string;
    bonusType: string;
    campaignId: string;
    campaignDescription: string;
    totalAmount: number;
    totalAmountTime: null;
    currentAmount: number;
    currentAmountTime: null;
    createdAt: string;
    expiryAt: string;
    bonusSource: string;
    bonusPlatform: string;
    status: Status;
    usageConditions: UsageCondition[];
    rewardName: string;
};

export type DetailType = 'sport' | 'competition' | 'event' | 'market' | 'selection';

export type UsageDetail = {
    id: string;
    name: string;
    type: DetailType;
};

type Details = Partial<Record<DetailType, UsageDetail>>;

type FreeSpinDetails = {
    id: string;
    name: string;
    type: string;
};
export interface UsageCondition {
    type: string;
    op: string;
    value: string[] | number;
    details?: Details | Record<string, FreeSpinDetails>;
}

export enum Status {
    Active = 'active',
    Expired = 'expired',
    Canceled = 'canceled',
    Claimed = 'claimed',
    RolledBack = 'rolled-back',
}
