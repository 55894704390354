import styled from '@emotion/styled';

export const EmptyStateWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    text-align: center;
`;

export const EmptyStateText = styled.p`
    font-size: 16px;
    color: #666;
    margin: 0;
`;
