import { EventId, SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { BetslipModelsContext } from './BetslipModelsContext';
import { LegItem } from 'src/domains/sportsbook/betting/betSlipState/LegItem';
import { CombinationItem } from 'src/domains/sportsbook/betting/betSlipState/CombinationItem';
import { RabItemState } from 'src/domains/sportsbook/betting/state/rabState/RabItemState';
import { AddToAllState } from 'src/domains/sportsbook/betting/betSlipState/AddToAllState';
import * as uuid from 'uuid';

export class BetslipSingleId {
    protected nominal: null = null;
    public readonly type: 'single' = 'single' as const;

    public constructor(
        private readonly context: BetslipModelsContext,
        public readonly id: SelectionId,
        //index in betrefferal
        public index: number
    ) {
        this.id = id;
    }

    public get reactKey(): string {
        return `BetslipSingleId-${this.id.key}-${this.index}`;
    }

    /**
     * @deprecated
     */
    public getOldIds(): [number, number] {
        return [this.id.toOldId(), this.index];
    }

    /**
     * @deprecated - To be improved
     */
    public get toOldIdForLifespan(): string {
        return this.id.toOldId().toString();
    }

    public getModel = (): LegItem => {
        return this.context.getLegItemById(this);
    };
}

export class BetslipCombinationId {
    protected nominal: null = null;
    public readonly type: 'combination' = 'combination' as const;

    public constructor(
        private readonly context: BetslipModelsContext,
        private readonly id: string
    ) {}

    public get reactKey(): string {
        return `BetslipCombinationId-${this.id}`;
    }

    public getModel = (): null | CombinationItem => {
        return this.context.getCombinationItemById(this.id);
    };

    public getId(): string {
        return this.id;
    }

    public isCastCombination(): boolean {
        return ['FC', 'RFC', 'CFC', 'TC', 'CTC'].includes(this.id);
    }
}

export class BetslipRabId {
    protected nominal: null = null;
    public readonly type: 'rab' = 'rab' as const;
    private readonly uuid = uuid.v4();

    public constructor(
        public readonly id: EventId,
        private getModelItem: () => RabItemState
    ) {}

    public get reactKey(): string {
        return `BetslipRabId-${this.uuid}-${this.id.key}`;
    }

    public getModel = (): RabItemState => {
        return this.getModelItem();
    };
}

export class BetslipAllSinglesId {
    protected nominal: null = null;
    public readonly type: 'all' = 'all' as const;

    public constructor(private readonly context: BetslipModelsContext) {}

    public getModel = (): AddToAllState => {
        return this.context.addToAllState;
    };
}
