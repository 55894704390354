import React from 'react';
import { observer } from 'src/utils/mobx-react';

import { useAppStateContext } from 'src/appState/AppState';
import { getError } from 'src/domains/layouts/webview/components/common/errorMessage/getError';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

export const ResponseErrorMessages = observer('ResponseErrorMessages', () => {
    const {
        appSportsBookState,
        common: {
            envVariables: { universe },
        },
    } = useAppStateContext();
    const { errorsList } = appSportsBookState.betSlipState.betSlipSummaryState;

    return (
        <>
            {errorsList.map((error) => (
                <Messages
                    type='info'
                    key={`${error.code}-${error.field ?? 'field'}-${error.resource}-${error.pointer ?? '0'}`}
                    message={getError(universe, error)}
                />
            ))}
        </>
    );
});
