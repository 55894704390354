import React, { useEffect, useMemo, useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    AmountWrapper,
    CreditsTabWrapper,
    FreeBetIconWrapped,
    TabButton,
    TabContent,
    Tabs,
    TotalAmount,
} from './CreditsTab.styled';
import { CreditsPagination } from './components/CreditTabPagination/CreditsPagination';
import { TabType, TabTypeLabels } from './types';
import { observer } from 'mobx-react-lite';
import { DarkLoadingSpinner } from 'src/domains/layouts/webview/components/loaders/loadingSpinner/LoadingSpinner';
import { FreeSpinIconWrapped } from '../summaryTab/AccountFreeBet/AccountFreeBet.styled';
import { EmptyState } from './components/EmptyState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Bonus, BonusState } from 'src/domains/players/state/BonusState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { FreeBets } from './components/Tabs/FreeBets/FreeBets';
import { FreeSpins } from './components/Tabs/FreeSpins';

export const CreditsTab = observer(() => {
    const {
        appPlayersState: { usersState, bonusState },
    } = useAppStateContext();

    const [activeTab, setActiveTab] = useState<TabType>(bonusState.getSelectedTab ?? TabType.FreeBets);
    const [currentPage, setCurrentPage] = useState(1);

    const activeTabConfig = tabConfig[activeTab];
    const displayedItems = activeTabConfig.getItems(bonusState);

    const totalPages = Math.ceil(displayedItems.length / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const paginatedItems = useMemo(
        () => displayedItems.slice(startIndex, startIndex + ITEMS_PER_PAGE),
        [displayedItems, startIndex]
    );

    useEffect(() => {
        bonusState.refreshBalance();
        bonusState.refreshBonuses();
    }, [activeTab, bonusState]);

    useEffect(() => {
        setCurrentPage(1);
    }, [activeTab]);

    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const totalAmount = activeTabConfig.getTotalAmount(bonusState, usersState);
    const ActiveComponent = activeTabConfig.component;

    return (
        <CreditsTabWrapper>
            <Tabs>
                {Object.values(TabType).map((tab) => (
                    <TabButton
                        key={tab}
                        isActive={activeTab === tab}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tabConfig[tab].label}
                    </TabButton>
                ))}
            </Tabs>

            <TabContent>
                <TotalAmount>
                    <I18n
                        langKey='account.creditsTab.totalAmountTitle'
                        defaultText='Total {tab}:'
                        params={{ tab: activeTabConfig.label }}
                    />

                    <AmountWrapper>
                        {activeTabConfig.icon}
                        <strong>{totalAmount}</strong>
                    </AmountWrapper>
                </TotalAmount>

                {bonusState.isLoadingBonuses ? (
                    <DarkLoadingSpinner />
                ) : (
                    <div>
                        {displayedItems.length > 0 ? (
                            <>
                                <ActiveComponent items={paginatedItems} />
                                <CreditsPagination
                                    handlePageChange={handlePageChange}
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                />
                            </>
                        ) : (
                            <EmptyState />
                        )}
                    </div>
                )}
            </TabContent>
        </CreditsTabWrapper>
    );
});

const ITEMS_PER_PAGE = 3;

type TabConfig = {
    [key in TabType]: {
        label: string;
        icon: JSX.Element | null;
        getItems: (bonusState: BonusState) => Bonus[];
        getTotalAmount: (bonusState: BonusState, usersState?: UsersState) => number | string;
        component: React.FC<{ items: Bonus[] }>;
    };
};

const tabConfig: TabConfig = {
    [TabType.FreeBets]: {
        label: TabTypeLabels[TabType.FreeBets],
        icon: <FreeBetIconWrapped />,
        getItems: (bonusState: BonusState) => bonusState.freeBets,
        getTotalAmount: (bonusState: BonusState, usersState?: UsersState) => {
            return usersState?.money(bonusState.freeBetBalance) ?? 0;
        },
        component: FreeBets,
    },
    [TabType.FreeSpins]: {
        label: TabTypeLabels[TabType.FreeSpins],
        icon: <FreeSpinIconWrapped />,
        getItems: (bonusState: BonusState) => bonusState.freeSpins,
        getTotalAmount: (bonusState: BonusState) => bonusState.freeSpinBalance,
        component: FreeSpins,
    },
} as const;
