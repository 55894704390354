import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import * as t from 'io-ts';
import { FreeBetIO } from 'src_server/handlers/apiWeb/betting/postPossibleBetsTypes';
import { ChannelIO, CountryIO, CurrencyIO, ErrorIO, ErrorsIO, RabLegIO, SmallLegIO } from './types';
import { PriceIO } from 'src/domains/sportsbook/betting/betSlipState/BetSlipSheredTypes';

export const SelectedBetsInfoIO = t.union([
    t.undefined,
    t.null,
    t.interface({
        numLinesSum: t.union([t.number, t.null, t.undefined]),
        totalPotentialReturn: t.union([t.string, t.null, t.undefined]),
        totalMinReturn: t.union([t.string, t.null, t.undefined]),
        calculatedStackPerLine: t.union([t.string, t.null, t.undefined]),
    }),
]);

export type SelectedBetsInfoType = t.TypeOf<typeof SelectedBetsInfoIO>;

export const BetIO = t.interface({
    id: t.string,
    type: t.string,
    stakePerLine: t.string,
    payout: t.union([t.undefined, t.number, t.null]),
    eachWay: t.boolean,
    legs: t.array(SmallLegIO),
    ip: t.string,
    country: CountryIO,
    currency: CurrencyIO,
    correlationId: t.union([t.string, t.undefined]),
    price: t.union([PriceIO, t.null, t.undefined]),
    maxStake: t.union([t.null, t.undefined, t.string]),
    potentialReturns: t.union([t.undefined, t.null, t.string]),
    potentialReturnsEw: t.union([t.undefined, t.null, t.string]),
    totalStake: t.union([t.undefined, t.string, t.null]),
    errors: t.array(ErrorIO),
    freeBets: t.array(FreeBetIO),
    // TODO: To remove, temporary solution to distinguish between available free bets and selected free bets
    selectedFreeBets: t.union([t.undefined, t.array(t.string)]),
    selection: t.union([t.undefined, t.null, t.interface({ id: t.number })]),
});

export type BetResponseType = t.TypeOf<typeof BetIO>;

export type BetResponseExtendedType = BetResponseType & { numLines: number };

const ParsedLegsIO = t.interface({
    eachWay: t.boolean,
    potentialReturns: t.union([t.string, t.null]),
    potentialReturnsAt: t.string,
    freeBets: t.array(FreeBetIO),
    errors: t.union([t.array(ErrorIO), t.undefined]),
    maxStake: t.union([t.string, t.null]),
    price: t.union([PriceIO, t.null, t.undefined]),
    // related: t.boolean,
    // 'singles-only': t.union([ t.boolean, t.undefined ])
});

export type ParsedLegsType = t.TypeOf<typeof ParsedLegsIO>;

const ParsedCombinationsIO = t.interface({
    type: t.string,
    ewOffered: t.boolean,
    name: t.string,
    potentialReturns: t.union([t.string, t.null]),
    potentialReturnsEw: t.union([t.string, t.null]),
    potentialReturnsAt: t.union([t.string, t.undefined]),
    maxStake: t.union([t.string, t.undefined]),
    numLines: t.number,
    legs: t.array(SmallLegIO),
    freeBets: t.array(FreeBetIO),
    errors: t.union([t.array(ErrorIO), t.undefined]),
    price: t.union([PriceIO, t.null, t.undefined]),
});

export type ParsedCombinationsType = t.TypeOf<typeof ParsedCombinationsIO>;

const PlayableBalanceAmountsIO = t.interface({
    requiredAmount: t.union([t.null, t.undefined, t.number]),
    currentAmount: t.union([t.null, t.undefined, t.number]),
});

export type PlayableBalanceAmountsType = t.TypeOf<typeof PlayableBalanceAmountsIO>;

export const ErrorRawIO = t.interface({
    code: t.string,
    debugDetails: t.union([t.null, t.unknown]),
    details: t.union([t.null, PlayableBalanceAmountsIO, t.undefined]),
    field: t.union([t.null, t.unknown]),
    leg: t.unknown,
    pointer: t.union([t.string, t.null, t.undefined]),
    resource: t.string,
});

export type ErrorRawType = t.TypeOf<typeof ErrorRawIO>;

const RabRawIO = t.interface({
    channel: ChannelIO,
    eachWay: t.boolean,
    legs: t.union([t.undefined, t.array(RabLegIO)]),
    stakePerLine: t.union([t.undefined, t.string]),
    type: t.string,
    payout: t.union([t.number, t.undefined, t.null]),
    platformId: t.union([t.string, t.undefined, t.null]),
    freeBets: t.union([t.array(FreeBetIO), t.undefined]),
    correlationId: t.string,
});

const ParsedPossibleBetsIO = t.interface({
    stakeOneCombinations: t.record(t.string, ParsedCombinationsIO),
    combinations: t.record(t.string, ParsedCombinationsIO),
    legs: t.record(t.string, ParsedLegsIO),
    related: t.boolean,
    singlesOnly: t.boolean,
    playableBalanceAmounts: t.union([PlayableBalanceAmountsIO, t.null, t.undefined]),
    bets: t.array(BetIO),
    relatedOnAdd: t.boolean,
    errors: t.array(ErrorRawIO),
    rabBets: t.union([t.array(RabRawIO), t.undefined, t.null]),
    selectedBetsInfo: SelectedBetsInfoIO,
});

export type ParsedPossibleBetsType = t.TypeOf<typeof ParsedPossibleBetsIO>;

export const decodeResponseGetPossibleBets = buildValidator('API - getPossibleBets', ParsedPossibleBetsIO, true);

const CombinationsForPossibleBets = t.interface({
    legs: t.array(SmallLegIO),
    potentialReturns: t.string,
    price: t.union([PriceIO, t.null, t.undefined]),
    ewOffered: t.boolean,
    name: t.string,
    maxStake: t.union([t.string, t.null]),
    stakePerLine: t.string,
    potentialReturnsEw: t.union([t.string, t.null]),
    potentialReturnsAt: t.union([t.string, t.null]),
    numLines: t.number,
    type: t.string,
    eachWay: t.boolean,
    freeBets: t.array(FreeBetIO),
});

export type CombinationsForPossibleBetsType = t.TypeOf<typeof CombinationsForPossibleBets>;

const LegsForPossibleBets = t.interface({
    eventId: t.number,
    marketId: t.number,
    marketName: t.union([t.string, t.null, t.undefined]),
    selectionId: t.number,
    selectionName: t.union([t.string, t.null, t.undefined]),
    betEventId: t.number,
    betEventName: t.union([t.string, t.null, t.undefined]),
    id: t.number,
    priceType: t.string,
    timestamp: t.number,
    stakePerLine: t.string,
    potentialReturns: t.union([t.string, t.null]),
    related: t.boolean,
    errors: t.array(ErrorsIO),
    eachWay: t.boolean,
    freeBets: t.array(FreeBetIO),
    maxStake: t.union([t.string, t.null]),
    potentialReturnsAt: t.string,
    price: t.union([PriceIO, t.null, t.undefined]),
    oldPrice: t.union([PriceIO, t.null, t.undefined]),
    name: t.string,
    potentialReturnsEw: t.string,
    type: t.string,
    index: t.union([t.number, t.null]),
});

export type LegsForPossibleBetsType = t.TypeOf<typeof LegsForPossibleBets>;

const BetsFromLocalStorageIO = t.interface({
    combinations: t.array(CombinationsForPossibleBets),
    legs: t.array(LegsForPossibleBets),
});

export type BetsFromLocalStorageType = t.TypeOf<typeof BetsFromLocalStorageIO>;

export const decodeBetsFromLocalStorage = buildValidator('API - betsFromLocalStorage', BetsFromLocalStorageIO, true);

export type CombinationsRequestType = CombinationsForPossibleBetsType;

interface ErrorResponseBodyType {
    status: 'error';
    data: Record<string, string>;
}

interface SuccessResponseBodyType {
    status: 'success';
    data: ParsedPossibleBetsType;
}

export type BettingPossibleBetsType = SuccessResponseBodyType | ErrorResponseBodyType | null;

const CombinationRawIO = t.interface({
    type: t.string,
    name: t.string,
    ewOffered: t.boolean,
    legs: t.union([t.undefined, t.array(SmallLegIO)]),
    numLines: t.number,
    delay: t.number,
    visibleForCustomer: t.union([t.undefined, t.boolean, t.null]),
    visibleForBackend: t.union([t.undefined, t.boolean, t.null]),
    potentialReturns: t.union([t.string, t.null, t.undefined]),
    potentialReturnsEw: t.union([t.string, t.null, t.undefined]),
    maxStake: t.union([t.undefined, t.null, t.string]),
    price: t.union([PriceIO, t.null, t.undefined]),
    eachWay: t.union([t.undefined, t.boolean]),
    stakePerLine: t.union([t.undefined, t.string]),
    freeBets: t.array(FreeBetIO),
    errors: t.union([t.null, t.undefined, t.array(ErrorIO)]),
});

export type CombinationRawType = t.TypeOf<typeof CombinationRawIO>;
export type RabRawType = t.TypeOf<typeof RabRawIO>;

export const ResponseBodyIO = t.interface({
    success: t.boolean,
    bets: t.array(BetIO),
    combinations: t.record(t.string, CombinationRawIO),
    stakeOneCombinations: t.record(t.string, CombinationRawIO),
    errors: t.array(ErrorRawIO),
    rabBets: t.array(RabRawIO),
    selectedBetsInfo: SelectedBetsInfoIO,
});

export type ResponseBodyPossibleBetsType = t.TypeOf<typeof ResponseBodyIO>;

const ResponseIO = t.union([
    t.interface({
        status: t.literal(200),
        bodyJson: ResponseBodyIO,
    }),
    t.interface({
        status: t.literal(400),
        bodyJson: ResponseBodyIO,
    }),
    t.interface({
        status: t.literal(500),
        bodyJson: t.interface({}),
    }),
    t.interface({
        status: t.literal(404),
        bodyJson: t.interface({}),
    }),
]);

export const decodeRawResponseGetPossibleBets = buildValidator('getPossibleBets -> ResponseIO', ResponseIO, true);

// ----------- for new request

export const SmallLegStrictIO = t.interface({
    type: t.union([t.undefined, t.string]),
    selection: t.union([t.undefined, t.interface({ id: t.number })]),
    market: t.union([t.undefined, t.interface({ id: t.number })]),
    event: t.union([t.undefined, t.interface({ id: t.number })]),
    price: t.union([PriceIO, t.null, t.undefined]),
    priceType: t.string,
});

export const SmallLegStrictWithEachWayIO = t.interface({
    type: t.union([t.undefined, t.null, t.string]),
    selection: t.union([t.undefined, t.null, t.interface({ id: t.number })]),
    market: t.union([t.undefined, t.null, t.interface({ id: t.number })]),
    event: t.union([t.undefined, t.null, t.interface({ id: t.number })]),
    price: t.union([PriceIO, t.null, t.undefined]),
    priceType: t.string,
    eachWay: t.boolean,
});

export type SmallLegStrictWithEachWayType = t.TypeOf<typeof SmallLegStrictWithEachWayIO>;

export const LegCombinationsForRequestIO = t.interface({
    id: t.union([t.string, t.null]),
    legs: t.array(SmallLegStrictWithEachWayIO),
    eachWay: t.boolean,
});

export type LegCombinationsForRequestType = t.TypeOf<typeof LegCombinationsForRequestIO>;

const AccountDataIO = t.interface({
    currency: t.string,
    country: t.string,
    ipUser: t.union([t.string, t.null]),
    id: t.union([t.number, t.null]),
});

export type AccountDataType = t.TypeOf<typeof AccountDataIO>;

const BetsForRequestIO = t.interface({
    id: t.string,
    type: t.string,
    freeBets: t.array(FreeBetIO),
    stakePerLine: t.string,
    payout: t.union([t.number, t.undefined, t.null]),
    eachWay: t.boolean,
    legs: t.array(SmallLegStrictIO),
    ip: t.string,
    channel: ChannelIO,
    country: t.union([t.string, t.null, t.undefined]),
    currency: t.string,
    correlationId: t.string,
});

export type BetsForRequestType = t.TypeOf<typeof BetsForRequestIO>;

export const GetPossibleBetsRequestNewIO = t.interface({
    channel: ChannelIO,
    isFreeBet: t.boolean,
    isFreeBetTax: t.boolean,
    accountData: AccountDataIO,
    bets: t.array(BetsForRequestIO),
    legCombinations: t.array(LegCombinationsForRequestIO),
    rabBets: t.union([t.array(RabRawIO), t.undefined, t.null]),
});
export type GetPossibleBetsRequestNewType = t.TypeOf<typeof GetPossibleBetsRequestNewIO>;

export const decodeRequestBody = buildValidator('API - decodeRequestBody', GetPossibleBetsRequestNewIO, true);
