/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React, { useEffect, useRef } from 'react';
import { CloseButton, Content, Overlay, PopupContainer, Title } from './HierarchyPopup.styled';

interface HierarchyPopupProps {
    title?: string;
    onClose: () => void;
    children: React.ReactNode;
}

export const HierarchyPopup = ({ title, onClose, children }: HierarchyPopupProps) => {
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!popupRef.current?.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    return (
        <Overlay>
            <PopupContainer ref={popupRef}>
                {title && <Title>{title}</Title>}
                <Content>{children}</Content>
                <CloseButton
                    size='small'
                    onClick={onClose}
                >
                    Close
                </CloseButton>
            </PopupContainer>
        </Overlay>
    );
};
