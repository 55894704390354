import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const Overlay = withConfig(
    (theme) => styled('div')`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${theme.star.popup.bgColorQuinary};
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    `
);

export const PopupContainer = withConfig(
    (theme) => styled('div')`
        background-color: ${theme.star.popup.bgColor};
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        max-width: 400px;
        width: 90%;
        animation: fadeIn 0.3s ease-in-out;

        @keyframes fadeIn {
            from {
                opacity: 0;
                transform: scale(0.9);
            }
            to {
                opacity: 1;
                transform: scale(1);
            }
        }
    `
);

export const Title = styled.h2`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const Content = withConfig(
    (theme) => styled('div')`
        font-size: 14px;
        color: ${theme.star.popup.txtColor};
        word-wrap: break-word;
        white-space: pre-wrap;
        margin-bottom: 15px;
    `
);

export const CloseButton = styled(Button)`
    width: 100%;
    padding: 10px;
`;
