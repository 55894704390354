import styled from '@emotion/styled';

export const EligibleDetailsWrapper = styled.div`
    margin-top: 8px;
    margin-bottom: 8px;
`;

export const EligibleDetailsItem = styled.div`
    margin-bottom: 8px;
`;
