import { action, makeObservable } from 'mobx';
import { BetSlipState } from 'src/domains/sportsbook/betting/state/BetSlipState';
import { SelectionBoostState } from 'src/domains/layouts/state/lifespanState/LifespanSocketState';
import { LifeSpanState } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { BetslipSingleId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';

export class BetSlipSelectionState {
    private readonly legId: BetslipSingleId;
    private readonly betSlipState: BetSlipState;

    public constructor(legId: BetslipSingleId, betSlipState: BetSlipState) {
        makeObservable(this);
        this.legId = legId;
        this.betSlipState = betSlipState;
    }

    @action public handleRemove = (
        lifeSpanState: LifeSpanState,
        selectionWithBoost: SelectionBoostState | null
    ): void => {
        const { basicBetSlipState, betSlipSummaryState, quickBetState } = this.betSlipState;
        const { isVisibleQuickBet } = quickBetState;
        const { onCloseQuickBet, onHideKeyboard, focusedSelectionId } = basicBetSlipState;
        const { clearErrors } = betSlipSummaryState;
        const legModel = this.legId.getModel();

        if (isVisibleQuickBet) {
            onCloseQuickBet();
        }
        if (focusedSelectionId !== null && focusedSelectionId === this.legId) {
            onHideKeyboard();
        }
        legModel.removeLeg();

        if (selectionWithBoost !== null) {
            if (this.betSlipState.legsIds2.length === 0) {
                lifeSpanState.lifeSpanSocketState.messageWithEmptyBetslip();
            }
            if (selectionWithBoost.selectionInfo?.reward?.active === true) {
                lifeSpanState.deactivateSelectionBoost(selectionWithBoost.selectionInfo);
            }
        }

        clearErrors();
    };
}
