/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React, { useEffect, useState } from 'react';
import { Icon, IconContainer, TooltipContainer, TooltipContent, TooltipTriangle } from './Tooltip.style';

type TooltipPropsType = {
    content: React.ReactNode;
    trigger?: 'hover' | 'click';
};

export const Tooltip = ({ content, trigger = 'hover' }: TooltipPropsType): JSX.Element => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const toggleTooltip = () => setIsTooltipVisible((prev) => !prev);
    const showTooltip = () => setIsTooltipVisible(true);
    const hideTooltip = () => setIsTooltipVisible(false);

    const eventHandlers =
        trigger === 'click'
            ? {
                  onClick: toggleTooltip,
              }
            : {
                  onMouseEnter: showTooltip,
                  onMouseLeave: hideTooltip,
              };

    const handleOutsideClick = (event: MouseEvent) => {
        const target = event.target;
        if (target instanceof HTMLElement && target.closest('.tooltip-container') == null) {
            setIsTooltipVisible(false);
        }
    };

    useEffect(() => {
        if (trigger === 'click') {
            document.addEventListener('click', handleOutsideClick);
            return () => {
                document.removeEventListener('click', handleOutsideClick);
            };
        }
    }, [trigger]);

    return (
        <TooltipContainer className={`${trigger === 'click' ? 'click-trigger' : 'hover-trigger'}`}>
            <IconContainer {...eventHandlers}>
                <Icon />
            </IconContainer>
            <TooltipTriangle className={`tooltip-triangle ${isTooltipVisible ? 'tooltip-triangle-visible' : ''}`} />
            <TooltipContent className={`tooltip-content ${isTooltipVisible ? 'tooltip-content-visible' : ''}`}>
                {content}
            </TooltipContent>
        </TooltipContainer>
    );
};
