/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Bonus, DetailType, UsageDetail } from 'src/domains/players/state/BonusState';
import { HierarchyData } from '../components/Tabs/FreeBets/EligibleDetailsList';

export const mapDetailsToHierarchy = (item: Bonus): Record<string, HierarchyData> => {
    const order: DetailType[] = ['sport', 'competition', 'event', 'market', 'selection'];

    const isUsageDetail = (value: unknown): value is UsageDetail =>
        typeof value === 'object' && value !== null && 'name' in value;

    return item.usageConditions.reduce<Record<string, HierarchyData>>((result, { value, details }) => {
        if (!Array.isArray(value) || details == null) return result;

        for (const id of value) {
            const detail = details[id as keyof typeof details];
            if (detail == null) continue;

            const typeNameMap: Partial<Record<DetailType, string>> = {};

            const sortedDetails = Object.entries(detail)
                .filter(([key, val]) => order.includes(key as DetailType) && isUsageDetail(val))
                .sort(([keyA], [keyB]) => order.indexOf(keyA as DetailType) - order.indexOf(keyB as DetailType));

            if (sortedDetails.length === 0) continue;

            let deepestType = '';
            let deepestValue: UsageDetail | null = null;

            const lastDetail = sortedDetails.at(-1);
            if (lastDetail !== undefined) {
                const [type, value] = lastDetail;
                if (isUsageDetail(value)) {
                    deepestType = type;
                    deepestValue = value;
                }
            }

            if (deepestValue !== null) {
                result[`${deepestType}|${deepestValue.name}`] = typeNameMap;
            }

            for (const [key, val] of sortedDetails) {
                if (isUsageDetail(val) && order.includes(key as DetailType)) {
                    typeNameMap[key as DetailType] = val.name;
                }
            }
        }

        return result;
    }, {});
};
