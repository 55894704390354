import { action, computed, makeObservable, observable } from 'mobx';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { RabBetType } from 'src/domains/sportsbook/betting/betting/postPlaceBet';
import { ChannelType } from 'src/domains/sportsbook/betting/betting/types';
import { BetslipRabId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import { RabItemState } from 'src/domains/sportsbook/betting/state/rabState/RabItemState';
import { Amount } from 'src_common/common/amount/Amount';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { FreeBetCreditsType, FreeBetType } from '../../betSlipState/BetSlipSheredTypes';
import { RabRawType } from '../../betting/getPossibleBetsTypes';

export class RabState {
    @observable private currentRabBasket: RabItemState | null = null;
    @observable private itemsInBetslip: Array<RabItemState> = [];

    @observable private rabPossibleBets: RabRawType[] = [];

    public constructor(
        private readonly sdkCustomer: SdkCustomer,
        private readonly configComponents: ConfigComponents,
        private readonly languagesState: LanguagesState,
        private readonly getIsRabFeatureOn: () => boolean,
        private readonly getChannel: () => ChannelType,
        private readonly decimalLength: number,
        private readonly getSelectedFreeBets: (id: string) => FreeBetType[],
        private readonly getSelectedFreeBetCredits: (id: string, stake: number) => FreeBetCreditsType[]
    ) {
        this.rabPossibleBets = [];

        makeObservable(this);
    }

    public getRabItemPossibleResponse(correlationId: string): RabRawType | undefined {
        return this.rabPossibleBets.find((item) => item.correlationId === correlationId);
    }

    public getAvailableFreeBets(correlationId: string): FreeBetType[] {
        return this.getRabItemPossibleResponse(correlationId)?.freeBets ?? [];
    }

    public getOrCreateRabBasket = (eventId: EventId): RabItemState => {
        const currentRabBasket = this.currentRabBasket;

        if (currentRabBasket === null) {
            return new RabItemState(
                this.sdkCustomer,
                this.configComponents,
                eventId,
                this.languagesState,
                this.getChannel,
                this.decimalLength,
                (id) => this.getAvailableFreeBets(id),
                (id) => this.getSelectedFreeBets(id),
                (id, stake) => this.getSelectedFreeBetCredits(id, stake)
            );
        }

        return currentRabBasket;
    };

    @action public updateRabPossibleBets = (rabPossibleBets: RabRawType[]): void => {
        this.rabPossibleBets = rabPossibleBets;
    };

    @action public addToBetslip = (): void => {
        if (this.currentRabBasket !== null) {
            if (this.currentRabBasket.postBody.length >= 2 && !this.currentRabBasket.rabStatus.conflictingBets) {
                const currentRabItem = this.currentRabBasket;
                const newRabItem = new RabItemState(
                    this.sdkCustomer,
                    this.configComponents,
                    this.currentRabBasket.eventId,
                    this.languagesState,
                    this.getChannel,
                    this.decimalLength,
                    (id) => this.getAvailableFreeBets(id),
                    (id) => this.getSelectedFreeBets(id),
                    (id, stake) => this.getSelectedFreeBetCredits(id, stake)
                );
                this.currentRabBasket = newRabItem;
                this.itemsInBetslip.push(currentRabItem);
            }
        }
    };

    @action public removeFromBetslip = (rabId: BetslipRabId): void => {
        this.itemsInBetslip = this.itemsInBetslip.filter((item) => item.id !== rabId);
    };

    @action public acceptChanges(): void {
        this.itemsInBetslip.map((itemValue: RabItemState) => {
            itemValue.acceptChanges();
        });
    }

    @computed public get activeBets(): Array<RabItemState> {
        const out = [];
        for (const item of this.itemsInBetslip) {
            out.push(item);
        }

        return out;
    }

    @computed public get activeBets2(): Array<BetslipRabId> {
        return this.itemsInBetslip.map((item) => item.id);
    }

    @computed public get areBetsWithoutOffer(): boolean {
        return this.activeBets.some((bet) => bet.rabOffer.length === 0);
    }

    @computed public get suspendedBets(): Array<RabItemState> {
        return this.itemsInBetslip.filter((rabItem) => rabItem.rabStatus.suspendedBets);
    }

    @action public toggleOpenForEvent = (eventId: EventId): void => {
        this.currentRabBasket =
            this.currentRabBasket === null
                ? new RabItemState(
                      this.sdkCustomer,
                      this.configComponents,
                      eventId,
                      this.languagesState,
                      this.getChannel,
                      this.decimalLength,
                      (id) => this.getAvailableFreeBets(id),
                      (id) => this.getSelectedFreeBets(id),
                      (id, stake) => this.getSelectedFreeBetCredits(id, stake)
                  )
                : null;
    };

    @action public onOpenForEvent = (eventId: EventId): void => {
        this.currentRabBasket = new RabItemState(
            this.sdkCustomer,
            this.configComponents,
            eventId,
            this.languagesState,
            this.getChannel,
            this.decimalLength,
            (id) => this.getAvailableFreeBets(id),
            (id) => this.getSelectedFreeBets(id),
            (id, stake) => this.getSelectedFreeBetCredits(id, stake)
        );
    };

    @action public closeRab = (): void => {
        this.currentRabBasket = null;
    };

    public isAvailableForEvent(eventId: EventId): boolean {
        if (this.getIsRabFeatureOn() === false) {
            return false;
        }
        const eventModel = eventId.getEventModel();

        if (eventModel === null) {
            return false;
        }

        if (eventModel.timeSettingsStarted === true) {
            return false;
        }

        const rabMarkets = eventId.getRabModel();

        if (rabMarkets === null) {
            return false;
        }

        return rabMarkets.markets.length > 0;
    }

    public get isBetBuilderOpen(): boolean {
        return this.currentRabBasket !== null;
    }

    public getBets(payout: Amount | null): Array<RabBetType> {
        const bets = [];
        for (const item of this.itemsInBetslip) {
            const rabBet = item.getRabBet(payout);

            if (rabBet !== null) {
                bets.push(rabBet);
            }
        }

        return bets;
    }

    @computed public get getBetsForPossibleBet(): Array<RabBetType> | null {
        const bets: Array<RabBetType> = [];
        for (const item of this.itemsInBetslip) {
            const rabBet = item.getRabBet(null);
            if (rabBet === null) {
                return null;
            }
            bets.push(rabBet);
        }

        return bets;
    }

    @action public clearAllRabData = (): void => {
        this.removeAllFromBetslip();
        this.clearRabList();
    };
    @action public removeAllFromBetslip(): void {
        this.itemsInBetslip = [];
    }

    @action public clearRabList = (): void => {
        const rabItem = this.currentRabBasket;
        if (rabItem !== null) {
            rabItem.postBody.map((item) => {
                if (item.market.name !== undefined) {
                    rabItem.removeSelection(item.market.name, item.selection.name);
                }
            });
        }
    };
}
