import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { EmptyStateText, EmptyStateWrapper } from './EmptyState.styled';

export const EmptyState = () => {
    return (
        <EmptyStateWrapper>
            <EmptyStateText>
                <I18n
                    langKey='account.bonus.emptyState'
                    defaultText='No items available'
                />
            </EmptyStateText>
        </EmptyStateWrapper>
    );
};
